import React, { forwardRef, useState } from "react";

import { CheckedState } from "@radix-ui/react-checkbox";
import { Check, ChevronDown } from "lucide-react";

import { ScrollArea } from "~/components/ui/scroll-area";
import { Button } from "~/components/ui-rework/button";
import { Checkbox } from "~/components/ui-rework/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "~/components/ui-rework/command";
import { Label } from "~/components/ui-rework/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui-rework/popover";
import { cn } from "~/lib/utils";

export type ComboboxOption = {
  value: string;
  label: React.ReactNode;
};

type ComboboxProps = {
  className?: string;
  clearable?: boolean;
  disabled?: boolean;
  emptyText?: string;
  limit?: number;
  maxHeight?: string;
  multiple?: boolean;
  name: string;
  options: ComboboxOption[];
  searchPlaceholder?: string;
  selectPlaceholder?: string;
  testid?: string;
  values?: string[];
  onValuesChange?: (value: string[]) => void;
};

const handleSelect = (
  option: ComboboxOption,
  clearable = false,
  values?: string[],
  onValuesChange?: (value: string[]) => void,
) => {
  if (values?.includes(option.value)) {
    if (!clearable && values.length === 1) return false;
    onValuesChange?.(values.filter((value) => value !== option.value));
  } else {
    onValuesChange?.([...(values ?? []), option.value]);
  }
};

const Combobox = forwardRef(
  (
    {
      className,
      clearable,
      disabled,
      emptyText,
      limit,
      maxHeight,
      multiple = true,
      name,
      options,
      searchPlaceholder,
      selectPlaceholder,
      testid,
      values,
      onValuesChange,
    }: ComboboxProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const [open, setOpen] = useState(false);

    const hasValue = values && values.length > 0;

    const selectedOptions =
      values
        ?.map((value) => options.find((opt) => opt.value === value))
        .filter((option): option is ComboboxOption => option !== undefined) ??
      [];

    const isLimitReached = !!(limit && values?.length === limit);

    const clearSelections = () => {
      onValuesChange?.([]);
    };

    const displayText =
      selectedOptions.length > 1
        ? `Selected (${selectedOptions.length})`
        : selectedOptions[0]?.label;

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <button
            aria-expanded={open}
            data-testid={testid}
            disabled={disabled}
            className={cn(
              "flex items-center w-full justify-between rounded-[10px] text-base bg-transparent border border-input p-2.5 ring-offset-select-ring",
              "data-[placeholder]:text-muted-foreground",
              "text-foreground",
              "hover:border-ring",
              "focus:outline-none focus:ring-1 focus:ring-ring focus:ring-offset-4",
              "focus:shadow focus:shadow-spread-1.5 focus:shadow-[rgb(0, 18, 26)]",
              "data-[state=open]:outline-none data-[state=open]:ring-1 data-[state=open]:ring-ring data-[state=open]:ring-offset-4",
              "disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:border-input",
              "[&>span]:truncate",
              className,
            )}
          >
            <span
              className="flex flex-wrap gap-x-2 gap-y-1 text-left"
              style={maxHeight ? { maxHeight: maxHeight } : {}}
            >
              {hasValue ? (
                displayText
              ) : (
                <span className="text-muted-foreground text-base">
                  {selectPlaceholder ?? "Select multiple..."}
                </span>
              )}
            </span>
            <ChevronDown
              className={cn(
                "ml-1 shrink-0 rotate-0 transition-transform text-muted-foreground",
                open && "rotate-180",
              )}
            />
          </button>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          className={cn(
            "p-0 w-[var(--radix-popper-anchor-width)] border-0 bg-transparent backdrop-blur-[1px]",
            "dark",
          )}
          scrollFix
        >
          <Command className="bg-popover-gradient backdrop-blur-[1px] text-foreground shadow-md min-w-[212px]">
            <CommandInput
              ref={ref}
              placeholder={searchPlaceholder ?? "Search for an option"}
            />
            {multiple ? (
              <>
                <CommandList>
                  <CommandGroup>
                    <div className="flex pl-2 justify-between">
                      <div className="gap-2 py-2 flex w-fit items-center justify-center">
                        <Checkbox
                          id={`${name}-select-all`}
                          checked={getCheckboxState(options, selectedOptions)}
                          onCheckedChange={() => {
                            onValuesChange?.(
                              options.map((option) => option.value),
                            );
                          }}
                        />
                        <Label
                          htmlFor={`${name}-select-all`}
                          size="m"
                          type="regular"
                          className="cursor-pointer mb-0"
                        >
                          Select all
                        </Label>
                      </div>
                      <Button
                        variant="ghost"
                        className="py-2"
                        onClick={clearSelections}
                      >
                        Clear all
                      </Button>
                    </div>
                  </CommandGroup>
                </CommandList>
                <CommandSeparator />
              </>
            ) : (
              <>
                <CommandList>
                  <CommandGroup>
                    <div className="flex justify-center w-full">
                      <Button
                        variant="ghost"
                        className="py-2 w-full"
                        onClick={clearSelections}
                      >
                        Clear selection
                      </Button>
                    </div>
                  </CommandGroup>
                </CommandList>
                <CommandSeparator />
              </>
            )}
            <CommandList>
              <ScrollArea thumbClassName="bg-white">
                <div className="max-h-60 py-1.5">
                  {multiple &&
                  options.length > 10 &&
                  selectedOptions.length > 0 ? (
                    <>
                      <CommandGroup className="px-2">
                        {selectedOptions.map((option) => (
                          <CommandItem
                            key={"_" + option.value}
                            value={"_" + option.value.toLowerCase().trim()}
                            onSelect={(selectedValue) => {
                              const option = options.find(
                                (option) =>
                                  "_" + option.value.toLowerCase().trim() ===
                                  selectedValue,
                              );
                              console.log(option);

                              if (!option) return null;

                              handleSelect(
                                option,
                                clearable,
                                values,
                                onValuesChange,
                              );
                            }}
                            disabled={
                              isLimitReached && !values?.includes(option.value)
                            }
                          >
                            <Check
                              className={cn("mr-2 h-4 w-4 opacity-0", {
                                "opacity-100": values?.includes(option.value),
                              })}
                            />
                            {option.label}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                      <CommandSeparator className="mx-2 my-1.5" />
                    </>
                  ) : null}

                  <CommandEmpty>{emptyText ?? "No results found"}</CommandEmpty>
                  <CommandGroup className="px-2">
                    {options.map((option) => (
                      <CommandItem
                        key={option.value}
                        value={option.value.toLowerCase().trim()}
                        onSelect={(selectedValue) => {
                          const option = options.find(
                            (option) =>
                              option.value.toLowerCase().trim() ===
                              selectedValue,
                          );

                          if (!option) return null;

                          if (
                            multiple ||
                            (!multiple && values?.[0] === selectedValue)
                          )
                            handleSelect(
                              option,
                              clearable,
                              values,
                              onValuesChange,
                            );
                          else
                            handleSelect(option, clearable, [], onValuesChange);
                        }}
                        disabled={
                          isLimitReached && !values?.includes(option.value)
                        }
                      >
                        <Check
                          className={cn("mr-2 h-4 w-4 opacity-0", {
                            "opacity-100": values?.includes(option.value),
                          })}
                        />
                        {option.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </div>
              </ScrollArea>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

const getCheckboxState = (
  options: ComboboxOption[],
  selectedOptions: ComboboxOption[],
): CheckedState => {
  if (selectedOptions.length === 0) return false;
  if (selectedOptions.length === options.length) return true;
  return "indeterminate";
};

export { Combobox };
