import React, { useEffect, useContext } from "react";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { API_PATHS, IData } from "~/api/ApiPaths";
import { AxiosContext } from "~/api/AxiosInstance";
import AlignLoadingIndicator from "~/components/AlignLoadingIndicator";
import Explorer from "~/components/explorer/Explorer";
import ExplorerLegend from "~/components/explorer/ExplorerLegend";
import FilterBadges from "~/components/explorer/filters/FilterBadges";
import FilterPopover from "~/components/explorer/filters/FilterPopover";
import ExplorerProvider, {
  useExplorerContext,
} from "~/context/ExplorerProvider";
import useCheckPermission from "~/hooks/useCheckPermission";
import useOverrides from "~/hooks/useOverrides";
import EmptyStateGraphic from "~/images/Empty-State-Graphic-Tree.svg";
import { useStore } from "~/models/AlignEditor";
import { useStore as useRootStore } from "~/models/Root";

const FlowMapWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  width: 98%;
  justify-content: center;
  margin: auto;
`;

const ExplorerRoute: React.FC = observer((props) => {
  const { initProgramTreeNode, isStoreReady, resetStoreIsReady } = useStore();
  const {
    getAuthToken,
    selectedProgramId,
    selectedPartnerId,
    getNoAvailableProgramsFlag,
  } = useRootStore();

  const axiosInstance = useContext(AxiosContext) ?? axios;

  const queryFn = (): Promise<any> =>
    axiosInstance
      .request({
        method: "GET",
        url: `${API_PATHS.PROGRAM_DETAILS}/${selectedProgramId}`,
        headers: { Authorization: getAuthToken() },
      })
      .then((response) => response.data);

  const { data, isSuccess, error, isLoading } = useQuery({
    queryKey: [
      "explorerData",
      `${API_PATHS.PROGRAM_DETAILS}/${selectedProgramId}`,
    ],
    enabled: selectedProgramId !== 0,
    queryFn,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["explorerData"] });
    resetStoreIsReady();
  }, [selectedProgramId, queryClient, resetStoreIsReady]);

  useEffect(() => {
    if (data && isSuccess && !isStoreReady) {
      const Data = data as IData;

      initProgramTreeNode([Data.entity]);
    }
  }, [data, initProgramTreeNode, isStoreReady, isSuccess]);

  if (isLoading) return <AlignLoadingIndicator />;

  const noProgramsFlag = getNoAvailableProgramsFlag();
  const {
    ProgramLabel,
    PartnerLabel,
  } = useOverrides();
  const programLabel = ProgramLabel();
  const partnerLabel = PartnerLabel();

  const { getRoleType } = useCheckPermission();

  //if no programs available, empty state
  const getEmptyStateHeaderText = () => {
    const roleType = getRoleType();
    if (roleType === "Agency Admin") {
      return "Whoops! There are no programs for your selected partner.";
    } else if (roleType === "Agency Editor") {
      return "Whoops! You don't have access to any programs for your selected partner.";
    } else if (roleType === "Partner User") {
      return "You don't have access to any programs yet!";
    } else {
      return null;
    }
  };

  const getEmptyStateSubText = () => {
    const roleType = getRoleType();
    if (roleType === "Agency Admin") {
      return (
        <>
          Go to the <b>Admin</b> tab to create a program to to view curriculum
          relationships and connections.
        </>
      );
    } else if (roleType === "Agency Editor") {
      return "Reach out to your administrator to view curriculum relationships and connections.";
    } else if (roleType === "Partner User") {
      return "Reach out to your administrator to view curriculum relationships and connections.";
    } else {
      return null;
    }
  };

  return (
    <FlowMapWrapper className="relative bg-background">
      {(selectedProgramId === 0 ||
        selectedPartnerId === 0 ||
        noProgramsFlag) && (
        <div className="h-full flex flex-row gap-9 px-24 justify-center items-center">
          <img
            alt="Program Page Graphic"
            src={EmptyStateGraphic}
            className="w-[632px] h-[632px] pointer-events-none select-none"
          />
          <div className="flex flex-col gap-4 justify-center max-w-fit">
            <h3 className="text-lg font-bold text-idesign-navy-100">
              {selectedPartnerId === 0
                ? `Select a ${partnerLabel} and a ${programLabel}`
                : noProgramsFlag
                ? getEmptyStateHeaderText()
                : `Select a ${programLabel}`}
            </h3>
            <p className="text-base font-normal text-idesign-navy-100 w-[415px]">
              {getNoAvailableProgramsFlag() ? (
                getEmptyStateSubText()
              ) : (
                <>
                  Select a{" "}
                  {selectedPartnerId === 0
                    ? partnerLabel?.toLowerCase() + " and"
                    : ""}{" "}
                  {programLabel?.toLowerCase()} to view curriculum relationships
                  and connections.
                </>
              )}
            </p>
          </div>
        </div>
      )}
      {isStoreReady && (
        <>
          <ExplorerProvider>
            <ExplorerRouteContent />
          </ExplorerProvider>
        </>
      )}
      {error && <div>{String(error)}</div>}
    </FlowMapWrapper>
  );
});

const ExplorerRouteContent = () => {
  const { selectedProgramId } = useRootStore();
  const { setBoundaryContainer } = useExplorerContext();

  return (
    <div className="h-full w-full" ref={setBoundaryContainer}>
      <div className="absolute top-4 left-4 z-10 flex gap-4">
        <FilterPopover />
        <FilterBadges />
      </div>
      <ExplorerLegend />
      <Explorer programId={selectedProgramId} />
    </div>
  );
};

export default ExplorerRoute;
