import React from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Hammer from "hammerjs";
import ReactDOM from "react-dom/client";
import { createGlobalStyle } from "styled-components";
import "@material-design-icons/font/filled.css";
import "@material-design-icons/font/outlined.css";

import "~/css/normalize.css";
import "~/css/webflow.css";
import "~/css/idesign-systemflow-v-1-4.webflow.css";
import "~/css/index.css";
import { BASE_URL } from "~/api/ApiPaths";
import AxiosInstanceProvider from "~/api/AxiosInstance";
import { Toaster } from "~/components/ui/toaster";
import { Toaster as ToasterNew } from "~/components/ui-rework/toaster";
import { TooltipProvider } from "~/components/ui-rework/tooltip";
import { colors } from "~/css/shared-styles";
import Gate from "~/Gate";
import reportWebVitals from "~/reportWebVitals";

window.Hammer = Hammer;
const GlobalStyle = createGlobalStyle`
  body {
    div.k-animation-container.k-animation-container-relative div.k-list-container div.k-list div.k-list-content ul.k-list-ul li.k-list-item.k-selected, div.k-animation-container.k-animation-container-relative.k-animation-container-shown div.k-list-container div.k-list div.k-list-content ul.k-list-ul li.k-list-item.k-selected {
      background-color: ${colors.linkBlue}
    }
  }
`;

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <QueryClientProvider client={queryClient}>
      <AxiosInstanceProvider config={{ baseURL: BASE_URL.URL }}>
        <TooltipProvider>
          <Gate />
          <Toaster />
          <ToasterNew />
        </TooltipProvider>
      </AxiosInstanceProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
