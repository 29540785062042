import React, { Fragment, useMemo, useRef, useState } from "react";

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ArrowDownUpIcon,
  ChevronDown,
  ChevronUp,
  FilePenLineIcon,
} from "lucide-react";
import { observer } from "mobx-react-lite";

import ActivityDialog from "~/components/program/dialogs/ActivityDialog";
import { CurriculumDialogBreadcrumb } from "~/components/program/dialogs/CurriculumDialogBreadcrumb";
import { DeleteCurriculumNode } from "~/components/program/dialogs/DeleteNodeDialog";
import MappingTable from "~/components/program/tables/MappingTable";
import { ScrollArea } from "~/components/ui/scroll-area";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "~/components/ui/table";
import { AutosaveIndicator } from "~/components/ui-rework/autosave-indicator";
import { Button } from "~/components/ui-rework/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui-rework/dialog";
import {
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger,
} from "~/components/ui-rework/sheet";
import {
  DraggableRow,
  RowDragHandleCell,
} from "~/components/ui-rework/table/DraggableRow";
import { useToast } from "~/components/ui-rework/use-toast";
import { cn } from "~/lib/utils";
import {
  useStore,
  IActivity,
  NodeTypes,
  ActivityEditable,
} from "~/models/AlignEditor";

const GRADING_STAKES = new Map<string, string>([
  ["High Stakes", "High"],
  ["Medium Stakes", "Medium"],
  ["Low Stakes", "Low"],
  ["None", "None"],
]);

type ActivityTableProps = {
  className?: string;
  courseId: number;
  moduleId: number;
};

const ActivityTable = observer(
  ({ className, courseId, moduleId }: ActivityTableProps) => {
    const columns = useMemo<ColumnDef<IActivity>[]>(
      () => [
        {
          id: "expand",
          header: "",
          cell: ({ row }) => (
            <div className="flex">
              <button
                {...{
                  onClick: () => row.toggleExpanded(!row.getIsExpanded()),
                  style: { cursor: "pointer" },
                }}
                data-testid="activity-expand"
              >
                {row.getIsExpanded() ? (
                  <ChevronUp size="16px" />
                ) : (
                  <ChevronDown size="16px" />
                )}
              </button>
            </div>
          ),
        },
        {
          id: "edit",
          header: "",
          cell: ({ row }) => (
            <div className="flex">
              <ActivityDialog
                activity={row.original}
                onSubmit={(data) => handleEditActivity(data, row.original)}
              >
                <button
                  data-testid="program-edit-activity"
                  title="Edit Activity"
                  aria-label="Edit Activity"
                >
                  <FilePenLineIcon className="stroke-ocean-120" size="16px" />
                </button>
              </ActivityDialog>
            </div>
          ),
        },
        {
          id: "code",
          header: "Code",
          cell: ({ row }) => (
            <div className="break-words">{row.original.code}</div>
          ),
        },
        {
          id: "name",
          header: "Name",
          cell: ({ row }) => (
            <div className="break-words">{row.original.name}</div>
          ),
        },
        {
          id: "description",
          header: "Description",
          cell: ({ row }) => (
            <div>
              <div className="line-clamp-2">{row.original.description}</div>
              {row.original.description &&
                row.original.description.length > 0 && (
                  <Dialog>
                    <DialogTrigger asChild>
                      <button className="bg-transparent text-ocean-120 underline font-bold">
                        View full description
                      </button>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogTitle className="text-lg mb-4">
                        Description
                      </DialogTitle>
                      <DialogDescription className="flex flex-col gap-4">
                        <CurriculumDialogBreadcrumb
                          courseId={row.original.course_id}
                          moduleId={row.original.module_id}
                          activityId={row.original.id}
                        />
                        <p className="text-base text-foreground">
                          {row.original.description}
                        </p>
                      </DialogDescription>
                    </DialogContent>
                  </Dialog>
                )}
            </div>
          ),
        },
        {
          id: "tot_minutes",
          header: () => <div className="text-end">Total Minutes</div>,
          cell: ({ row }) => (
            <div className="text-end">{row.original.tot_minutes}</div>
          ),
        },
        {
          id: "activity_type",
          header: "Type",
          cell: ({ row }) => <div>{row.original.activity_type}</div>,
        },
        {
          id: "is_assessment",
          header: () => <div className="text-center">Assessment</div>,
          cell: ({ row }) => (
            <div className="text-center">
              {row.original.is_assessment ? "Yes" : "No"}
            </div>
          ),
        },
        {
          id: "modality",
          header: "Modality",
          cell: ({ row }) => <div>{row.original.modality}</div>,
        },
        {
          id: "collaboration",
          header: "Collaboration",
          cell: ({ row }) => <div>{row.original.collaboration}</div>,
        },
        {
          id: "experience_type",
          header: "Experience Type",
          cell: ({ row }) => <div>{row.original.experience_type}</div>,
        },
        {
          id: "engagement_types",
          header: "Engagement Types",
          cell: ({ row }) => <div>{row.original.engagement_types}</div>,
        },
        {
          id: "blooms",
          header: "Blooms",
          cell: ({ row }) => <div>{row.original.blooms}</div>,
        },
        {
          id: "grading_points",
          header: () => <div className="text-end">Points</div>,
          cell: ({ row }) => (
            <div className="text-end">{row.original.grading_points}</div>
          ),
        },
        {
          id: "grading_stakes",
          header: "Stakes",
          cell: ({ row }) => (
            <div>
              {GRADING_STAKES.get(row.original.grading_stakes ?? "") ?? "None"}
            </div>
          ),
        },
        {
          id: "delete",
          header: "",
          cell: ({ row }) => (
            <DeleteCurriculumNode
              id={row.original.id}
              type={NodeTypes.Activity}
              display_name={row.original.display_name}
              onDelete={deleteActivity}
            />
          ),
        },
      ],
      [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const {
      getProgram,
      getModuleActivities,
      saveActivityChanges,
      deleteCurriculumNode,
    } = useStore();

    const { toast } = useToast();
    const handleSaveSuccess = () =>
      toast({
        variant: "success",
        title: "Changes Saved in Queue",
        description: `Activity changes saved in queue.`,
      });

    const [data, setData] = useState<IActivity[]>(
      getModuleActivities(courseId, moduleId),
    );

    const handleAddActivity = (activity: ActivityEditable) => {
      getProgram()?.addActivity(activity, courseId, moduleId);
      setData(getModuleActivities(courseId, moduleId));
      handleSaveSuccess();
    };

    const handleEditActivity = (
      activityUpdates: ActivityEditable,
      activityOriginal: IActivity,
    ) => {
      const activity = {
        ...activityOriginal,
        ...activityUpdates,
        engagement_types: activityUpdates.engagement_types?.join(", "),
        blooms: activityUpdates.blooms?.join(", "),
        grading_stakes: activityUpdates.grading_stakes ?? undefined,
      };
      setData(saveActivityChanges(activity, courseId, moduleId));
      handleSaveSuccess();
    };

    const deleteActivity = (activityId: number) => {
      deleteCurriculumNode(activityId, NodeTypes.Activity);
      setData(getModuleActivities(courseId, moduleId));
      handleSaveSuccess();
    };

    const [expanded, setExpanded] = useState<ExpandedState>({});

    const table = useReactTable({
      data,
      columns,
      state: {
        expanded,
      },
      onExpandedChange: setExpanded,
      getCoreRowModel: getCoreRowModel(),
    });

    const newActivity = {
      course_id: courseId,
      module_id: moduleId,
    } as IActivity;

    /** Edit display order table */
    const columnsReorder = useMemo<ColumnDef<IActivity>[]>(
      () => [
        {
          id: "drag-handle",
          cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
        },
        {
          id: "display_name",
          accessorKey: "display_name",
          header: "Activities",
          cell: ({ row }) => (
            <div className="text-base">{row.original.display_name}</div>
          ),
        },
      ],
      [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const dataIds = React.useMemo<UniqueIdentifier[]>(
      () => data?.map(({ id }) => id),
      [data],
    );

    const tableReorder = useReactTable({
      columns: columnsReorder,
      data,
      getCoreRowModel: getCoreRowModel(),
      getRowId: (row) => row.id as unknown as string, //required because row indexes will change
    });

    const sensors = useSensors(
      useSensor(MouseSensor, {}),
      useSensor(TouchSensor, {}),
      useSensor(KeyboardSensor, {}),
    );

    const handleUpdateOrder = (items: IActivity[]) => {
      items.forEach((item) => {
        saveActivityChanges(item, courseId, moduleId);
      });
      setData([...getModuleActivities(courseId, moduleId)]);
      setExpanded({});
      setLastReordered(new Date());
    };

    const handleDragEnd = (event: DragEndEvent) => {
      const { active, over } = event;
      if (active && over && active.id !== over.id) {
        setData((data) => {
          const oldIndex = dataIds.indexOf(active.id);
          const newIndex = dataIds.indexOf(over.id);

          const result = [...arrayMove(data, oldIndex, newIndex)]; //this is just a splice util

          const newData = result.map((item, index) => {
            return { ...item, display_order: index };
          });

          handleUpdateOrder(newData);
          return newData;
        });
      }
    };

    const onReorderSheetOpenChange = (open: boolean) => {
      if (!open) init.current = true;
    };

    const [lastReordered, setLastReordered] = useState<Date | null>(null);
    const init = useRef(true);

    return (
      <>
        <div className="bg-gradient-1 text-white font-semibold flex gap-2 items-center justify-between w-full px-3 py-2 border-t border-l border-idesign-navy-120">
          <div className="flex gap-2">
            <span className="text-base">Activities</span>
            <ActivityDialog onSubmit={handleAddActivity} activity={newActivity}>
              <Button
                className="dark text-sm py-1 px-2 font-normal"
                variant="outline"
                testid="program-add-activity"
              >
                Add Activity
              </Button>
            </ActivityDialog>
          </div>

          <Sheet onOpenChange={onReorderSheetOpenChange}>
            <SheetTrigger asChild>
              <Button
                className="dark text-sm py-1 px-2 font-normal flex gap-1.5"
                variant="outline"
              >
                <ArrowDownUpIcon size="16" />
                Edit Activity Order
              </Button>
            </SheetTrigger>
            <SheetContent className="w-[65%] min-w-[686px] p-10">
              <ScrollArea className="h-full" thumbClassName="bg-gradient-1">
                <div className="flex flex-col gap-6">
                  <SheetTitle className="text-xl">
                    Edit Activity Order
                  </SheetTitle>

                  <CurriculumDialogBreadcrumb
                    courseId={courseId}
                    moduleId={moduleId}
                  />

                  {data.length > 0 && (
                    <DndContext
                      collisionDetection={closestCenter}
                      modifiers={[restrictToVerticalAxis]}
                      onDragEnd={handleDragEnd}
                      sensors={sensors}
                    >
                      <div className="overflow-y-auto rounded-md border border-t-0 border-idesign-navy-120">
                        <Table className="table-fixed overflow-hidden border-collapse border-spacing-0">
                          <colgroup>
                            <col style={{ width: "32px" }} />
                            <col style={{ width: "500px" }} />
                          </colgroup>
                          <TableHeader className="rounded-md bg-gradient-1">
                            {tableReorder
                              .getHeaderGroups()
                              .map((headerGroup) => (
                                <TableRow
                                  key={headerGroup.id}
                                  className={cn(
                                    "hover:bg-transparent",
                                    "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                                  )}
                                >
                                  {headerGroup.headers.map(
                                    (header, idx, headers) => {
                                      if (idx < 1) return null;

                                      return (
                                        <TableHead
                                          className={cn(
                                            "text-white text-lg border-x border-idesign-navy-120 px-2 py-3 h-auto",
                                            "last:border-r-0 first:border-l-0",
                                          )}
                                          colSpan={2}
                                          key={header.id}
                                        >
                                          {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                              )}
                                        </TableHead>
                                      );
                                    },
                                  )}
                                </TableRow>
                              ))}
                          </TableHeader>
                          <TableBody>
                            <SortableContext
                              items={dataIds}
                              strategy={verticalListSortingStrategy}
                            >
                              {tableReorder.getRowModel().rows.map((row) => (
                                <DraggableRow key={row.id} row={row} />
                              ))}
                            </SortableContext>
                          </TableBody>
                        </Table>
                      </div>
                    </DndContext>
                  )}
                </div>
              </ScrollArea>
              <div className="absolute right-[68px] top-[17px]">
                <AutosaveIndicator
                  lastSaved={lastReordered}
                  setLastSaved={setLastReordered}
                  init={init}
                  savedMessage="All changes saved in queue"
                />
              </div>
            </SheetContent>
          </Sheet>
        </div>
        {data.length > 0 && (
          <div className="w-full flex text-[0px]">
            <Table
              className={cn(
                "max-w-fit overflow-x-auto table-fixed",
                "border border-r-0 border-idesign-navy-120",
                className,
              )}
            >
              <colgroup>
                <col style={{ width: "33px" }} />
                <col style={{ width: "33px" }} />
                <col style={{ width: "120px" }} />
                <col style={{ width: "33%", minWidth: "200px" }} />
                <col style={{ width: "66%", minWidth: "200px" }} />
                <col style={{ width: "104px" }} />
                <col style={{ width: "104px" }} />
                <col style={{ width: "96px" }} />
                <col style={{ width: "156px" }} />
                <col style={{ width: "104px" }} />
                <col style={{ width: "172px" }} />
                <col style={{ width: "134px" }} />
                <col style={{ width: "110px" }} />
                <col style={{ width: "70px" }} />
                <col style={{ width: "68px" }} />
                <col style={{ width: "33px" }} />
              </colgroup>
              <TableHeader className="bg-gradient-1">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow
                    key={headerGroup.id}
                    className="hover:bg-transparent"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          className={cn(
                            "text-white font-bold border-x border-idesign-navy-120 p-2 py-4",
                            "last:border-r-0 first:border-x-0 [&:nth-child(2)]:border-x-0",
                          )}
                          key={header.id}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <Fragment key={row.id}>
                      <TableRow
                        key={row.id + "_original"}
                        className={cn(
                          "hover:bg-gray-200 border-b-0",
                          "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                          {
                            "bg-muted": row.index % 2 !== 0,
                            "bg-white": row.index % 2 === 0,
                          },
                        )}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            key={cell.id}
                            className={cn(
                              "p-2 border-x border-idesign-navy-120",
                            )}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                      {row.getIsExpanded() ? (
                        <tr
                          key={row.id + "_expanded"}
                          className={cn({
                            "bg-muted": row.index % 2 !== 0,
                            "bg-white": row.index % 2 === 0,
                          })}
                        >
                          <TableCell
                            colSpan={columns.length}
                            className={cn("pl-8 pr-0 py-0")}
                          >
                            <MappingTable
                              className={cn({
                                "border-b border-idesign-navy-120":
                                  row.index !==
                                  table.getRowModel().rows.length - 1,
                              })}
                              nodeId={row.original.id}
                              nodeType={NodeTypes.Activity}
                            />
                          </TableCell>
                        </tr>
                      ) : null}
                    </Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        )}
      </>
    );
  },
);

export default ActivityTable;
