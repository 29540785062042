import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { SearchIcon } from "lucide-react";

import { API_PATHS } from "~/api/ApiPaths";
import UserDetailsDrawer from "~/components/admin/UserDetailsDrawer";
import { ChevronsSortIcon } from "~/components/icons/ChevronsSortIcon";
import LastLoginDialog from "~/components/LastLoginDialog";
import { ScrollArea } from "~/components/ui/scroll-area";
import { Button } from "~/components/ui-rework/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui-rework/dialog";
import { Input } from "~/components/ui-rework/input";
import {
  Pagination,
  PaginationContent,
  PaginationFirst,
  PaginationItem,
  PaginationLast,
  PaginationNext,
  PaginationPrevious,
} from "~/components/ui-rework/pagination";
import { Select } from "~/components/ui-rework/select";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "~/components/ui-rework/sheet";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui-rework/table";
import {
  TableFilterCombobox,
  tableFilterComboboxArrayFn,
  tableFilterComboboxFn,
} from "~/components/ui-rework/table-headers";
import useAxios from "~/hooks/useAxios";
import { cn } from "~/lib/utils";
import { IAppUser, useStore } from "~/models/Root";
import { getReadableDate } from "~/utilities/DateFormat";

type UserRow = IAppUser & { roles: string[] };

interface UserListTableProps {
  triggerRefresh: boolean;
  setTriggerRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserListTable: React.FC<UserListTableProps> = (props) => {
  const { triggerRefresh, setTriggerRefresh } = props;
  const displayReady = useRef(false);
  const [usersData, setUsersData] = useState<UserRow[]>([]);
  const { data, authToken, error, requestUrl, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const { getAuthToken } = useStore();
  const usersFetched = useRef(false);
  const usersSet = useRef(false);
  const finishedFetchingAll = useRef(false);
  const [currentData, setCurrentData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "name", desc: false },
  ]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [dialogVisible, SetDialogVisible] = useState(false);
  const [dialogDetails, setDialogDetails] = useState<IAppUser | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [drawerData, setDrawerData] = useState<IAppUser | null>(null);

  useEffect(() => {
    if (data && currentData !== data && !usersSet.current) {
      // Set user data
      setCurrentData(data);
      const allUsersList: UserRow[] = [];
      data.entity.forEach((item: UserRow) => {
        allUsersList.push({
          id: item.id,
          email: item.email,
          first_name: item.first_name,
          last_name: item.last_name,
          name: item.name,
          password: "",
          agency_id: item.agency_id,
          partner_id: item.partner_id,
          account_status: item.account_status,
          user_type: item.user_type,
          title: item.title,
          last_login: item.last_login,
          roles: item.roles,
        });
      });
      usersSet.current = true;
      setUsersData(allUsersList);
      finishedFetchingAll.current = true;
      setRefetch(false);
      displayReady.current = false;
    } else if (!usersFetched.current || refetch) {
      // fetch user data
      usersFetched.current = true;
      displayReady.current = false;
      requestUrl.current = `${API_PATHS.GET_USER_LIST}`;
      authToken.current = getAuthToken();
      fetch();
    } else if (error) {
      console.log(error);
    }
  }, [
    data,
    error,
    authToken,
    requestUrl,
    fetch,
    getAuthToken,
    currentData,
    refetch,
  ]);

  const triggleRefetch = () => {
    displayReady.current = false;
    usersFetched.current = false;
    usersSet.current = false;
    setRefetch(true);
  };

  useEffect(() => {
    if (triggerRefresh) {
      triggleRefetch();
      setTriggerRefresh(false);
    }
  }, [triggerRefresh, setTriggerRefresh]);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      triggleRefetch();
    }
  };

  const columns = useMemo<ColumnDef<UserRow>[]>(
    () => [
      {
        accessorKey: "name",
        header: ({ column }) => {
          return (
            <div className="flex gap-2">
              <Button
                className={cn(
                  "p-0 items-center justify-start",
                  "hover:bg-transparent hover:!border-none hover:!outline-none active:bg-transparent",
                )}
                variant="ghost"
                onClick={() =>
                  column.toggleSorting(column.getIsSorted() === "asc")
                }
              >
                Name
                <ChevronsSortIcon direction={column.getIsSorted()} />
              </Button>
              <Input
                placeholder=""
                icon={<SearchIcon />}
                iconPosition="right"
                value={(column.getFilterValue() as string) ?? ""}
                onChange={(event) => column.setFilterValue(event.target.value)}
                className={cn(
                  "bg-transparent text-base",
                  "flex-grow focus:!border active:!border",
                  {
                    "border-0":
                      column.getFilterValue() === undefined ||
                      column.getFilterValue() === "",
                  },
                )}
                data-testid="admin-user-filter"
              />
            </div>
          );
        },
      },
      {
        accessorKey: "email",
        header: ({ column }) => {
          return (
            <div className="flex gap-2">
              <Button
                className={cn(
                  "p-0 items-center justify-start",
                  "hover:bg-transparent hover:!border-none hover:!outline-none active:bg-transparent",
                )}
                variant="ghost"
                onClick={() =>
                  column.toggleSorting(column.getIsSorted() === "asc")
                }
              >
                Email
                <ChevronsSortIcon direction={column.getIsSorted()} />
              </Button>
              <Input
                placeholder=""
                icon={<SearchIcon />}
                iconPosition="right"
                value={
                  (table.getColumn("email")?.getFilterValue() as string) ?? ""
                }
                onChange={(event) =>
                  table.getColumn("email")?.setFilterValue(event.target.value)
                }
                className={cn(
                  "bg-transparent text-base",
                  "flex-grow focus:!border active:!border",
                  {
                    "border-0":
                      column.getFilterValue() === undefined ||
                      column.getFilterValue() === "",
                  },
                )}
              />
            </div>
          );
        },
      },
      {
        accessorKey: "user_type",
        filterFn: tableFilterComboboxFn,
        header: ({ column, table }) => {
          const typeSet = new Set<string>();
          usersData
            .filter(
              (user) =>
                user.user_type !== undefined && user.user_type.trim() !== "",
            )
            .map((user) => user.user_type as string)
            .forEach((type) => typeSet.add(type));

          return (
            <div className="flex gap-2 items-center">
              <span>Type</span>
              <TableFilterCombobox
                column={column}
                options={
                  [...typeSet]
                    .sort((a, b) => a.localeCompare(b))
                    .map((type) => {
                      return { label: type, value: type };
                    }) ?? []
                }
                table={table}
                name="Type"
              />
            </div>
          );
        },
      },
      {
        id: "roles",
        accessorKey: "roles",
        filterFn: tableFilterComboboxArrayFn,
        header: ({ column, table }) => {
          const roleSet = new Set<string>();
          usersData
            .flatMap((user) => user.roles)
            .forEach((role) => roleSet.add(role));

          return (
            <div className="flex gap-2 items-center">
              <span>Role</span>
              <TableFilterCombobox
                column={column}
                options={
                  [...roleSet]
                    .sort((a, b) => a.localeCompare(b))
                    .map((role) => {
                      return { label: role, value: role };
                    }) ?? []
                }
                table={table}
                name="roles"
                multiple={false}
              />
            </div>
          );
        },
        cell: ({ row }) => <span>{row.original.roles.join(", ")}</span>,
      },
      {
        accessorKey: "account_status",
        filterFn: tableFilterComboboxFn,
        header: ({ column, table }) => {
          const statusSet = new Set<string>();
          usersData
            .filter(
              (user) =>
                user.account_status !== undefined &&
                user.account_status.trim() !== "",
            )
            .map((user) => user.account_status as string)
            .forEach((status) => statusSet.add(status));

          return (
            <div className="flex gap-2 items-center">
              <span>Status</span>
              <TableFilterCombobox
                column={column}
                options={
                  [...statusSet]
                    .sort((a, b) => a.localeCompare(b))
                    .map((status) => {
                      return { label: status, value: status };
                    }) ?? []
                }
                table={table}
                name="Status"
              />
            </div>
          );
        },
      },
      {
        accessorKey: "last_login",
        header: "Last Login",
        cell: ({ row }) => {
          const data = row.original;
          if (data?.last_login && data?.last_login > 0) {
            const date_display = getReadableDate(data.last_login);
            return (
              <>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    SetDialogVisible(true);
                    setDialogDetails(row.original);
                  }}
                >
                  <span className="underline text-align-blue">
                    {date_display}
                  </span>
                </Button>
              </>
            );
          }
        },
      },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => {
          const data = row.original;
          return (
            <Button
              size="sm"
              variant="outline"
              onClick={() => {
                setDrawerData(data);
                toggleDrawer();
              }}
              testid="admin-user-details"
            >
              User Details
            </Button>
          );
        },
      },
    ],
    [usersData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const table = useReactTable({
    data: usersData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    autoResetPageIndex: false,
    state: {
      sorting,
      columnFilters,
    },
  });

  const [pageIndex, setPageIndex] = useState(0);
  const firstPage = () => {
    table.firstPage();
    setPageIndex(0);
  };
  const previousPage = () => {
    table.previousPage();
    setPageIndex(pageIndex - 1);
  };
  const nextPage = () => {
    table.nextPage();
    setPageIndex(pageIndex + 1);
  };
  const lastPage = () => {
    table.lastPage();
    setPageIndex(table.getPageOptions().length - 1);
  };

  return (
    <>
      <div
        className="light h-full w-full grid gap-2 overflow-hidden rounded-md  border-idesign-navy-120"
        style={{ gridTemplateRows: "auto auto 1fr" }}
      >
        <ScrollArea
          className="w-full"
          horizontal
          vertical
          thumbClassName="z-50 bg-gradient-1"
        >
          <Table className="border border-idesign-navy-120">
            <TableHeader className="bg-gradient-1 dark h-16">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="hover:bg-transparent">
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        className={cn(
                          "text-white border-x border-idesign-navy-120 p-2 text-base",
                          "last:border-r-0 first:border-x-0",
                          {
                            "min-w-[236px]": header.id === "name",
                            "min-w-[238px]": header.id === "email",
                            "min-w-[180px]": [
                              "user_type",
                              "title",
                              "account_status",
                            ].includes(header.id),
                          },
                        )}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={cn(
                      "hover:bg-gray-200 border-b border-idesign-navy-120 last:border-b-0 bg-white",
                      "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                    )}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className="p-2 border-x border-idesign-navy-120"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ScrollArea>
        <div className="flex flex-row items-center justify-center w-full py-1.5">
          <div className="min-w-fit font-bold">
            Showing Items {pageIndex * 10}-
            {pageIndex === table.getPageCount() - 1
              ? table.getRowCount()
              : (pageIndex + 1) * 10}{" "}
            of {table.getRowCount()}
          </div>
          <Pagination className="w-fit mx-0 pr-1 md:absolute right-0">
            <PaginationContent>
              <PaginationItem>
                <PaginationFirst
                  onClick={firstPage}
                  disabled={!table.getCanPreviousPage()}
                />
              </PaginationItem>
              <PaginationItem>
                <PaginationPrevious
                  onClick={previousPage}
                  disabled={!table.getCanPreviousPage()}
                />
              </PaginationItem>
              Page
              <Select
                fieldName="pageSelect"
                options={table.getPageOptions().map((page) => ({
                  label: (page + 1).toString(),
                  value: page.toString(),
                }))}
                handleChange={(_, newValue) => {
                  setPageIndex(parseInt(newValue));
                  table.setPageIndex(parseInt(newValue));
                }}
                value={pageIndex.toString()}
                className="w-min"
              />
              <PaginationItem>
                <PaginationNext
                  disabled={!table.getCanNextPage()}
                  onClick={nextPage}
                />
              </PaginationItem>
              <PaginationItem>
                <PaginationLast
                  disabled={!table.getCanNextPage()}
                  onClick={lastPage}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </div>
      </div>
      {dialogDetails && dialogVisible && (
        <Dialog open={dialogVisible} onOpenChange={SetDialogVisible}>
          <DialogContent className="max-w-[80vw] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>
                <h1 className="text-lg font-semibold mb-0">
                  {dialogDetails.first_name} {dialogDetails.last_name} Session
                  Details
                </h1>
              </DialogTitle>
            </DialogHeader>
            <LastLoginDialog userDetails={dialogDetails} />
          </DialogContent>
        </Dialog>
      )}
      {isOpen && (
        <Sheet open={isOpen} onOpenChange={toggleDrawer}>
          <SheetContent className="!w-1/2 !min-w-[400px] p-0">
            <div className="mx-auto w-full max-w-m h-full">
              <SheetHeader className="flex flex-row justify-items-center items-center justify-between">
                <SheetTitle className="p-4 mx-2 justify-items-center align-text-center">
                  {" "}
                  {drawerData?.first_name} {drawerData?.last_name}
                </SheetTitle>
              </SheetHeader>
              <UserDetailsDrawer userDetailsData={drawerData} />
            </div>
          </SheetContent>
        </Sheet>
      )}
    </>
  );
};

export default UserListTable;
