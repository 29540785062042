import React, { Fragment, useMemo, useRef, useState } from "react";

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ArrowDownUpIcon,
  ChevronDown,
  ChevronUp,
  FilePenLineIcon,
} from "lucide-react";
import { observer } from "mobx-react-lite";

import { CurriculumDialogBreadcrumb } from "~/components/program/dialogs/CurriculumDialogBreadcrumb";
import { DeleteCurriculumNode } from "~/components/program/dialogs/DeleteNodeDialog";
import ModuleDialog from "~/components/program/dialogs/ModuleDialog";
import ActivityTable from "~/components/program/tables/ActivityTable";
import MappingTable from "~/components/program/tables/MappingTable";
import { ScrollArea } from "~/components/ui/scroll-area";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "~/components/ui/table";
import { AutosaveIndicator } from "~/components/ui-rework/autosave-indicator";
import { Button } from "~/components/ui-rework/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui-rework/dialog";
import {
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger,
} from "~/components/ui-rework/sheet";
import {
  DraggableRow,
  RowDragHandleCell,
} from "~/components/ui-rework/table/DraggableRow";
import { useToast } from "~/components/ui-rework/use-toast";
import { cn } from "~/lib/utils";
import {
  useStore,
  IModule,
  NodeTypes,
  ModuleEditable,
} from "~/models/AlignEditor";

type ModuleTableProps = {
  className?: string;
  courseId: number;
  course_display_name?: string;
};

const ModuleTable = observer(({ className, courseId }: ModuleTableProps) => {
  const columns = useMemo<ColumnDef<IModule>[]>(
    () => [
      {
        id: "expand",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <button
              data-testid="module-expand"
              {...{
                onClick: () => row.toggleExpanded(!row.getIsExpanded()),
                style: { cursor: "pointer" },
              }}
            >
              {row.getIsExpanded() ? (
                <ChevronUp size="16px" />
              ) : (
                <ChevronDown size="16px" />
              )}
            </button>
          </div>
        ),
      },
      {
        id: "edit",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <ModuleDialog
              module={row.original}
              onSubmit={(data) => handleEditModule(data, row.original)}
            >
              <button
                data-testid="program-edit-module"
                title="Edit Module"
                aria-label="Edit Module"
              >
                <FilePenLineIcon className="stroke-ocean-120" size="16px" />
              </button>
            </ModuleDialog>
          </div>
        ),
      },
      {
        id: "code",
        header: "Code",
        cell: ({ row }) => (
          <div className="break-words">{row.original.code}</div>
        ),
      },
      {
        id: "name",
        header: "Name",
        cell: ({ row }) => (
          <div className="break-words">{row.original.name}</div>
        ),
      },
      {
        id: "description",
        header: "Description",
        cell: ({ row }) => (
          <div>
            <div className="line-clamp-2">{row.original.description}</div>
            {row.original.description &&
              row.original.description.length > 0 && (
                <Dialog>
                  <DialogTrigger asChild>
                    <button className="bg-transparent text-ocean-120 underline font-bold">
                      View full description
                    </button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogTitle className="text-lg mb-4">
                      Description
                    </DialogTitle>
                    <DialogDescription className="flex flex-col gap-4">
                      <CurriculumDialogBreadcrumb
                        courseId={row.original.course_id}
                        moduleId={row.original.id}
                      />
                      <p className="text-base text-foreground">
                        {row.original.description}
                      </p>
                    </DialogDescription>
                  </DialogContent>
                </Dialog>
              )}
          </div>
        ),
      },
      {
        id: "week_number",
        header: () => <div className="text-end">Week</div>,
        cell: ({ row }) => (
          <div className="text-end">{row.original.week_number}</div>
        ),
      },
      {
        id: "delete",
        header: "",
        cell: ({ row }) => (
          <DeleteCurriculumNode
            id={row.original.id}
            type={NodeTypes.Module}
            display_name={row.original.display_name}
            onDelete={deleteModule}
          />
        ),
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const {
    getProgram,
    getCourseModules,
    saveModuleChanges,
    deleteCurriculumNode,
  } = useStore();

  const { toast } = useToast();
  const handleSaveSuccess = () =>
    toast({
      variant: "success",
      title: "Changes Saved in Queue",
      description: `Module changes saved in queue.`,
    });

  const [data, setData] = useState<IModule[]>(getCourseModules(courseId));

  const handleAddModule = (module: ModuleEditable) => {
    getProgram()?.addModule(module, courseId);
    setData(getCourseModules(courseId));
    handleSaveSuccess();
  };

  const handleEditModule = (
    moduleUpdates: ModuleEditable,
    moduleOriginal: IModule,
  ) => {
    const module = { ...moduleOriginal, ...moduleUpdates };
    setData(saveModuleChanges(module, courseId));
    handleSaveSuccess();
  };

  const deleteModule = (moduleId: number) => {
    deleteCurriculumNode(moduleId, NodeTypes.Module);
    setData(getCourseModules(courseId));
    handleSaveSuccess();
  };

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
  });

  const newModule = { course_id: courseId } as IModule;

  /** Edit display order table */
  const columnsReorder = useMemo<ColumnDef<IModule>[]>(
    () => [
      {
        id: "drag-handle",
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
      },
      {
        id: "display_name",
        accessorKey: "display_name",
        header: "Modules",
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const dataIds = React.useMemo<UniqueIdentifier[]>(
    () => data?.map(({ id }) => id),
    [data],
  );

  const tableReorder = useReactTable({
    columns: columnsReorder,
    data,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id as unknown as string, //required because row indexes will change
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const handleUpdateOrder = (items: IModule[]) => {
    items.forEach((item) => {
      saveModuleChanges(item, courseId);
    });
    setData([...getCourseModules(courseId)]);
    setExpanded({});
    setLastReordered(new Date());
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setData((data) => {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);

        const result = [...arrayMove(data, oldIndex, newIndex)]; //this is just a splice util

        const newData = result.map((item, index) => {
          return { ...item, display_order: index };
        });

        handleUpdateOrder(newData);
        return newData;
      });
    }
  };

  const onReorderSheetOpenChange = (open: boolean) => {
    if (!open) init.current = true;
  };

  const [lastReordered, setLastReordered] = useState<Date | null>(null);
  const init = useRef(true);

  return (
    <>
      <div className="bg-gradient-1 text-white font-semibold flex gap-2 justify-between items-center w-full px-3 py-2 border-t border-l border-idesign-navy-120">
        <div className="flex gap-2 items-center">
          <span className="text-base">Modules</span>
          <ModuleDialog module={newModule} onSubmit={handleAddModule}>
            <Button
              className="dark text-sm py-1 px-2 font-normal"
              variant="outline"
              testid="program-add-module"
            >
              Add Module
            </Button>
          </ModuleDialog>
        </div>

        <Sheet onOpenChange={onReorderSheetOpenChange}>
          <SheetTrigger asChild>
            <Button
              className="dark text-sm py-1 px-2 font-normal flex gap-1.5"
              variant="outline"
            >
              <ArrowDownUpIcon size="16" />
              Edit Module Order
            </Button>
          </SheetTrigger>
          <SheetContent className="w-[65%] min-w-[686px] p-10">
            <ScrollArea className="h-full" thumbClassName="bg-gradient-1">
              <div className="flex flex-col gap-6">
                <SheetTitle className="text-xl">Edit Module Order</SheetTitle>

                <CurriculumDialogBreadcrumb courseId={courseId} />

                {data.length > 0 && (
                  <DndContext
                    collisionDetection={closestCenter}
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={handleDragEnd}
                    sensors={sensors}
                  >
                    <div className="overflow-y-auto rounded-md border border-t-0 border-idesign-navy-120 mb-4">
                      <Table className="table-fixed overflow-hidden border-collapse border-spacing-0">
                        <colgroup>
                          <col style={{ width: "32px" }} />
                          <col style={{ width: "500px" }} />
                        </colgroup>
                        <TableHeader className="rounded-md bg-gradient-1">
                          {tableReorder.getHeaderGroups().map((headerGroup) => (
                            <TableRow
                              key={headerGroup.id}
                              className={cn(
                                "hover:bg-transparent",
                                "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                              )}
                            >
                              {headerGroup.headers.map(
                                (header, idx, headers) => {
                                  if (idx < 1) return null;

                                  return (
                                    <TableHead
                                      className={cn(
                                        "text-white text-lg border-x border-idesign-navy-120 px-2 py-3 h-auto",
                                        "last:border-r-0 first:border-l-0",
                                      )}
                                      colSpan={2}
                                      key={header.id}
                                    >
                                      {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                          )}
                                    </TableHead>
                                  );
                                },
                              )}
                            </TableRow>
                          ))}
                        </TableHeader>
                        <TableBody>
                          <SortableContext
                            items={dataIds}
                            strategy={verticalListSortingStrategy}
                          >
                            {tableReorder.getRowModel().rows.map((row) => (
                              <DraggableRow key={row.id} row={row} />
                            ))}
                          </SortableContext>
                        </TableBody>
                      </Table>
                    </div>
                  </DndContext>
                )}
              </div>
            </ScrollArea>
            <div className="absolute right-[68px] top-[17px]">
              <AutosaveIndicator
                lastSaved={lastReordered}
                setLastSaved={setLastReordered}
                init={init}
                savedMessage="All changes saved in queue"
              />
            </div>
          </SheetContent>
        </Sheet>
      </div>
      {data.length > 0 && (
        <Table
          className={cn(
            "table-fixed border border-r-0 border-idesign-navy-120",
            className,
          )}
        >
          <colgroup>
            <col style={{ width: "33px" }} />
            <col style={{ width: "33px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "33%" }} />
            <col style={{ width: "66%" }} />
            <col style={{ width: "100px" }} />
            <col style={{ width: "33px" }} />
          </colgroup>
          <TableHeader className="bg-gradient-1">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="hover:bg-transparent">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className={cn(
                        "text-white font-bold border-x border-idesign-navy-120 p-2 py-4",
                        "last:border-r-0 first:border-x-0 [&:nth-child(2)]:border-x-0",
                      )}
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  <TableRow
                    key={row.id + "_original"}
                    className={cn(
                      "hover:bg-gray-200 border-b-0",
                      "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                      {
                        "bg-muted": row.index % 2 !== 0,
                        "bg-white": row.index % 2 === 0,
                      },
                    )}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className="p-2 border-x border-idesign-navy-120"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.getIsExpanded() ? (
                    <tr
                      key={row.id + "_expanded"}
                      className={cn("", {
                        "bg-muted": row.index % 2 !== 0,
                        "bg-white": row.index % 2 === 0,
                      })}
                    >
                      <TableCell
                        colSpan={columns.length}
                        className="pl-8 pr-0 py-0"
                      >
                        <MappingTable
                          nodeId={row.original.id}
                          nodeType={NodeTypes.Module}
                        />
                        <ActivityTable
                          className={cn({
                            "border-b-0":
                              row.index === table.getRowModel().rows.length - 1,
                          })}
                          courseId={courseId}
                          moduleId={row.original.id}
                        />
                      </TableCell>
                    </tr>
                  ) : null}
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
});

export default ModuleTable;
