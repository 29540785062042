import { useContext } from "react";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { ApiUrls } from "~/api/ApiPaths";
import { AxiosContext } from "~/api/AxiosInstance";
import { MappingSuggestion } from "~/components/program/mapping/types";
import { useToast } from "~/components/ui-rework/use-toast";
import { useMappingSheetContext } from "~/context/MappingSheetProvider";
import { ItemRow } from "~/models/AlignEditor";
import { useStore as useRootStore } from "~/models/Root";

type SuggestionResponse = {
  id: number;
  name: string;
  reason: string;
};

export const useAIListItemsSuggestionsQuery = () => {
  const { listItemHierarchy, nodeId, parentItemId, selectedListId } =
    useMappingSheetContext();
  const items = listItemHierarchy ?? [];
  const { toast } = useToast();

  const axiosInstance = useContext(AxiosContext) ?? axios;
  const { getAuthToken } = useRootStore();

  const body = {
    activity_id: nodeId,
    list_id: selectedListId,
    listitem_ids: flattenItems(items, parentItemId),
  };

  const queryFn = (): Promise<MappingSuggestion[]> =>
    axiosInstance
      .post(
        ApiUrls.mapping.getAIListItemSuggestionsForActivity,
        { ...body },
        { headers: { Authorization: getAuthToken() } },
      )
      .then((response) => buildSuggestions(response.data.entity ?? [], items))
      .catch((error) => {
        throw error;
      });

  const retry = (failureCount: number, error: Error) => {
    if (failureCount < 2) return true;

    toast({
      variant: "destructive",
      title: "Uh oh! Something went wrong",
      description: "There was a problem with your request.",
    });
    console.error(error);
    return false;
  };
  const query = useQuery({
    queryKey: [
      ApiUrls.mapping.getAIListItemSuggestionsForActivity,
      nodeId,
      selectedListId,
      parentItemId,
    ],
    enabled: false,
    queryFn,
    retry,
  });

  return query;
};

const flattenItems = (itemRows: ItemRow[], parentId?: number) => {
  const result: number[] = [];

  const traverse = (row: ItemRow) => {
    result.push(row.id);
    if (row.items) {
      for (const child of row.items) {
        traverse(child);
      }
    }
  };

  const traverseAll = (rows: ItemRow[]) => {
    for (const row of rows) {
      traverse(row);
    }
  };

  const findAndTraverse = (rows: ItemRow[]): boolean => {
    for (const row of rows) {
      if (row.id === parentId) {
        traverse(row);
        return true; // Stop searching once the parentId is found
      }
      if (row.items && findAndTraverse(row.items)) {
        return true;
      }
    }
    return false;
  };

  if (parentId) findAndTraverse(itemRows);
  else traverseAll(itemRows);

  return result;
};

const buildSuggestions = (
  suggestions: SuggestionResponse[],
  listItemHierarchy: ItemRow[] | undefined,
): MappingSuggestion[] => {
  const map = new Map<number, SuggestionResponse>();
  suggestions.forEach((suggestion) => map.set(suggestion.id, suggestion));

  function prune(item: MappingSuggestion): MappingSuggestion | null {
    const prunedChildren = item.items
      ?.map(prune)
      .filter((child) => child !== null);

    if (map.has(item.id) || (prunedChildren?.length ?? 0) > 0) {
      return {
        ...item,
        ...map.get(item.id),
        items: prunedChildren as MappingSuggestion[],
      };
    }

    return null;
  }

  return listItemHierarchy
    ?.map(prune)
    .filter((root) => root !== null) as MappingSuggestion[];
};
