import React, { useEffect, useRef, useState } from "react";

import { CheckCircle2, Loader } from "lucide-react";

import { useMappingSheetContext } from "~/context/MappingSheetProvider";
import { useDebounce } from "~/hooks/useDebounce";

export const MappingAutosaveIndicator = () => {
  const { lastSaved } = useMappingSheetContext();

  const init = useRef(true);
  const [animate, setAnimate] = useState(false);

  const debouncedLastSaved = useDebounce(lastSaved, 500);

  useEffect(() => {
    if (debouncedLastSaved) {
      init.current = false;
      setAnimate(true);

      const timer = setTimeout(() => {
        setAnimate(false);
      }, 750);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [debouncedLastSaved]);

  if (init.current) return null;

  return (
    <div>
      <div className="flex gap-2.5 items-center justify-center text-idesign-navy-70 stroke-idesign-navy-70">
        {animate ? (
          <>
            <Loader className="animate-spin" />
            <span>Saving...</span>
          </>
        ) : (
          <>
            <CheckCircle2 />
            <span>All changes saved in queue</span>
          </>
        )}
      </div>
    </div>
  );
};
