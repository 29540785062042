import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";

import { Button } from "~/components/ui-rework/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui-rework/dialog";
import { Input } from "~/components/ui-rework/input";
import { Label } from "~/components/ui-rework/label";
import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import { IProgramTreeNode, ProgramEditable } from "~/models/AlignEditor";

const generateDisplayName = (
  data: ProgramEditable,
  program: IProgramTreeNode | undefined,
) => {
  return data.code && data.name
    ? `${data.code} - ${data.name}`
    : program?.display_name ?? "";
};

type ProgramDialogProps = {
  program: IProgramTreeNode;
  children: ReactNode;
  onSubmit: (data: ProgramEditable) => void;
};

const ProgramDialog = ({ program, children, onSubmit }: ProgramDialogProps) => {
  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      code: program.code,
      name: program.name,
    },
  });

  const handleOpenChange = (open: boolean) => {
    if (open)
      reset({
        code: program.code,
        name: program.name,
      });
  };

  const { programLabel } = useProgramEditorContext();

  return (
    <Dialog onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <form
          autoComplete="off"
          onSubmit={handleSubmit((data) =>
            onSubmit({
              ...data,
              display_name: generateDisplayName(data, program),
            }),
          )}
        >
          <DialogTitle className="mt-1">{`Edit ${programLabel}`}</DialogTitle>
          <div className="grid gap-4 py-4">
            <div className="flex flex-col gap-2">
              <Label htmlFor="code" className="font-bold mb-0">
                Code
              </Label>
              <Input
                id="code"
                className="bg-transparent"
                placeholder={`${programLabel} Code`}
                {...register("code", {
                  required: "Code is required",
                  minLength: {
                    value: 1,
                    message: "Code is required",
                  },
                  setValueAs: (value) => value.trim(),
                })}
                aria-describedby={
                  errors.code?.message ? "code-error" : undefined
                }
              />
              {errors.code?.message && (
                <span
                  id="code-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.code.message}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <Label htmlFor="name" className="font-bold mb-0">
                Name
              </Label>
              <Input
                id="name"
                className="bg-transparent"
                placeholder={`${programLabel} Name`}
                {...register("name", {
                  required: "Name is required",
                  minLength: {
                    value: 1,
                    message: "Name is required",
                  },
                  setValueAs: (value) => value.trim(),
                })}
                aria-describedby={
                  errors.name?.message ? "name-error" : undefined
                }
              />
              {errors.name?.message && (
                <span
                  id="name-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.name.message}
                </span>
              )}
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                className="font-normal"
                type="submit"
                disabled={!isDirty || !isValid}
              >
                Save Changes
              </Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ProgramDialog;
