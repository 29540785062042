import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Building } from "lucide-react";

import { FormField } from "~/components/ui/form";
import { Button } from "~/components/ui-rework/button";
import {
  FormControl,
  FormItem,
  FormMessage,
} from "~/components/ui-rework/form";
import { Input } from "~/components/ui-rework/input";
import { Label } from "~/components/ui-rework/label";
import useOverrides from "~/hooks/useOverrides";

interface IAddNewProgram {
  programDetailsData: any | null;
}

const AddNewProgram: React.FC<IAddNewProgram> = (props) => {
  const { programDetailsData } = props;
  const { ProgramLabel, PartnerLabel } = useOverrides();
  const programLabel = ProgramLabel();
  const partnerLabel = PartnerLabel();
  const form = useFormContext();
  const [doOnce, setDoOnce] = useState(true);

  useEffect(() => {
    if (doOnce) {
      setDoOnce(false);
      form.reset({
        programCode: "",
        programName: "",
      });
    }
  }, [form, doOnce]);

  return (
    <div>
      <div className="flex flex-col gap-6">
        <h3 className="text-xl font-bold">Add {programLabel}</h3>
        <p>
          You're creating a {programLabel?.toLowerCase()} for{" "}
          <b>{programDetailsData?.short_name}</b>.<br />
          Provide the necessary details to add a {programLabel?.toLowerCase()}{" "}
          to the selected {partnerLabel?.toLowerCase()}.
        </p>
        <FormField
          control={form.control}
          name="programCode"
          render={({ field }) => (
            <FormItem>
              <Label htmlFor="programCode" className="font-bold">
                Program Code (i.e. MHA)
              </Label>
              <FormControl>
                <Input
                  iconPosition="left"
                  icon={<Building />}
                  className="bg-transparent"
                  placeholder="Program Code"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="programName"
          render={({ field }) => (
            <FormItem>
              <Label htmlFor="programName" className="font-bold">
                Program Name (i.e. Masters of Health Administration)
              </Label>
              <FormControl>
                <Input
                  iconPosition="left"
                  icon={<Building />}
                  className="bg-transparent"
                  placeholder="Program Name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="w-full flex justify-end mt-6">
        <Button type="submit">Add New {programLabel}</Button>
      </div>
    </div>
  );
};

export default AddNewProgram;
