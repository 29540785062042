import React, { useEffect, useRef, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { Laptop, Server } from "lucide-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { API_PATHS, ApiUrls, IData } from "~/api/ApiPaths";
import AlignCurriculumDataGrid from "~/components/AlignCurriculumDataGrid";
import { ScrollArea } from "~/components/ui/scroll-area";
import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import useAxios from "~/hooks/useAxios";
import useCheckPermission from "~/hooks/useCheckPermission";
import useOverrides from "~/hooks/useOverrides";
import EmptyStateGraphic from "~/images/empty-state.svg";
import { cn } from "~/lib/utils";
import { useStore } from "~/models/AlignEditor";
import { useStore as useRootStore } from "~/models/Root";

const DataGridEditorRoute: React.FC = observer((props) => {
  const queryClient = useQueryClient();

  const apiFetched = useRef(false);
  const {
    initProgramTreeNode,
    isStoreReady,
    // resetDrawerLists,
    // resetDrawerSelectedListItems,
    resetStoreIsReady,
  } = useStore();
  const { getNoAvailableProgramsFlag } = useRootStore();
  const { getAuthToken, selectedProgramId, selectedPartnerId } = useRootStore();
  // const { isEditingMappings, setIsEditingMappings } = useProgramEditorContext();
  const selectedProgramIdRef = useRef(selectedProgramId);
  const { data, error, requestUrl, fetch, authToken, resetFetchedData } =
    useAxios({ method: "GET", initialValue: null });
  const [loading, setLoading] = useState(false);
  const {
    ProgramLabel,
    PartnerLabel,
    CoursesLabel,
    ModulesLabel,
    ActivitiesLabel,
  } = useOverrides();
  const programLabel = ProgramLabel();
  const partnerLabel = PartnerLabel();
  const coursesLabel = CoursesLabel()?.toLowerCase();
  const modulesLabel = ModulesLabel()?.toLowerCase();
  const activitiesLabel = ActivitiesLabel()?.toLowerCase();
  const pageLoaded = useRef(false);
  const { getRoleType } = useCheckPermission();
  const noProgramsFlag = getNoAvailableProgramsFlag();

  //this reloads the page when you navigate back, which solves problem of partner/program being changed and not reloading the page
  //but this causes a bit of awkward stuttering when navigating back, phase between loading and old data being displayed
  useEffect(() => {
    if (!pageLoaded.current) {
      pageLoaded.current = true;
      resetFetchedData();
      resetStoreIsReady();
    }
  }, [resetFetchedData, resetStoreIsReady]);

  useEffect(() => {
    if (!apiFetched.current && selectedProgramId !== 0) {
      apiFetched.current = true;
      selectedProgramIdRef.current = selectedProgramId;
      //This path is hard coded for now, but will be dynamic in the future.
      requestUrl.current = `${API_PATHS.PROGRAM_DETAILS}/${selectedProgramId}`;
      authToken.current = getAuthToken();
      setLoading(true);
      fetch();
    }
  }, [
    requestUrl,
    authToken,
    fetch,
    getAuthToken,
    apiFetched,
    selectedProgramId,
    setLoading,
  ]);

  useEffect(() => {
    const Data = data as IData;
    if (data && data.issuccess && apiFetched.current === true) {
      setLoading(false);
      initProgramTreeNode([Data.entity]);
    }
  }, [data, initProgramTreeNode, setLoading]);

  useEffect(() => {
    if (selectedProgramId !== selectedProgramIdRef.current) {
      apiFetched.current = false;
      resetFetchedData();
      resetStoreIsReady();
      selectedProgramIdRef.current = selectedProgramId;
    }
  }, [selectedProgramId, resetFetchedData, resetStoreIsReady]);

  //if no programs available, empty state
  const getEmptyStateHeaderText = () => {
    const roleType = getRoleType();
    if (roleType === "Agency Admin") {
      return "Whoops! There are no programs for your selected partner.";
    } else if (roleType === "Agency Editor") {
      return "Whoops! You don’t have access to any programs for your selected partner.";
    } else if (roleType === "Partner User") {
      return "You don't have access to any programs yet!";
    } else {
      return null;
    }
  };

  const getEmptyStateSubText = () => {
    const roleType = getRoleType();
    if (roleType === "Agency Admin") {
      return (
        <>
          Go to the <b>Admin</b> tab to create a program to view and edit your
          program details, including courses, modules, and activities. You can
          also map curriculum to lists here.
        </>
      );
    } else if (roleType === "Agency Editor") {
      return "Reach out to your administrator to view and edit your program details, including courses, modules, and activities. You can also map curriculum to lists here.";
    } else if (roleType === "Partner User") {
      return "Reach out to your administrator to view and edit your program details, including courses, modules, and activities. You can also map curriculum to lists here.";
    } else {
      return null;
    }
  };

  return (
    <ScrollArea
      className="light bg-background p-3 pt-0 w-full"
      viewportClassName={cn({
        "[&>div]:h-full":
          selectedProgramId === 0 ||
          selectedPartnerId === 0 ||
          (selectedProgramId !== 0 &&
            selectedPartnerId !== 0 &&
            loading &&
            !isStoreReady &&
            error === ""),
      })}
      thumbClassName="bg-gradient-1"
    >
      <title>Programs - Align App</title>
      {(selectedProgramId === 0 ||
        selectedPartnerId === 0 ||
        noProgramsFlag) && (
        <div className="h-full flex flex-row gap-9 px-24 justify-center items-center">
          <img
            alt="Program Page Graphic"
            src={EmptyStateGraphic}
            className="w-[632px] h-[632px] pointer-events-none select-none"
          />
          <div className="flex flex-col gap-4 justify-center max-w-fit">
            <h3 className="text-lg font-bold text-idesign-navy-100">
              {selectedPartnerId === 0
                ? `Select a ${partnerLabel} and a ${programLabel}`
                : noProgramsFlag
                ? getEmptyStateHeaderText()
                : `Select a ${programLabel}`}
            </h3>
            <p className="text-base font-normal text-idesign-navy-100 w-[415px]">
              {getNoAvailableProgramsFlag() ? (
                getEmptyStateSubText()
              ) : (
                <>
                  Select a{" "}
                  {selectedPartnerId === 0
                    ? partnerLabel?.toLowerCase() + " and"
                    : ""}{" "}
                  {programLabel?.toLowerCase()} to view and edit your{" "}
                  {programLabel?.toLowerCase()} details, including{" "}
                  {coursesLabel}, {modulesLabel}, and {activitiesLabel}. You can
                  also map curriculum to lists here.
                </>
              )}
            </p>
          </div>
        </div>
      )}
      {isStoreReady && selectedProgramId !== 0 && selectedPartnerId !== 0 && (
        <AlignCurriculumDataGrid
          reload={() => {
            apiFetched.current = false;
            resetFetchedData();
            resetStoreIsReady();
            queryClient.invalidateQueries({
              queryKey: [ApiUrls.mapping.getAIListItemSuggestionsForActivity],
            });
          }}
        />
      )}
      {selectedProgramId !== 0 &&
        selectedPartnerId !== 0 &&
        loading &&
        !isStoreReady &&
        error === "" && (
          <div className="my-auto h-full flex flex-col gap-8 items-center justify-center light">
            <span className="text-idesign-navy-100 text-lg font-bold">
              Fetching {programLabel}...
            </span>

            <div className="flex justify-center items-center gap-6">
              <div className="flex justify-center items-center rounded-full h-[72px] w-[72px] bg-ocean-100">
                <Server size={38} className="stroke-white" />
              </div>
              <span
                style={{ animationDelay: "0ms" }}
                className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
              />
              <span
                style={{ animationDelay: "150ms" }}
                className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
              />
              <span
                style={{ animationDelay: "300ms" }}
                className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
              />
              <span
                style={{ animationDelay: "450ms" }}
                className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
              />
              <div className="flex justify-center items-center rounded-full h-[72px] w-[72px] bg-ocean-30">
                <Laptop size={38} className="stroke-white" />
              </div>
            </div>

            <span className="text-idesign-navy-100 text-center max-w-72">
              We're fetching your {programLabel?.toLowerCase()} details. Please
              wait.
            </span>
          </div>
        )}
      {error !== "" && <div>{String(error)}</div>}
    </ScrollArea>
  );
});

export default DataGridEditorRoute;
