import React, { useState } from "react";

import { Row } from "@tanstack/react-table";

import { Combobox } from "~/components/ui-rework/combobox";
import { cn } from "~/lib/utils";

export const TableFilterCombobox = ({
  column,
  options,
  name,
  table,
  multiple,
}: any) => {
  const [value, setValue] = useState<string[]>([]);

  const handleValuesChange = (newValue: string[]) => {
    setValue(newValue);
    table.getColumn(column.id)?.setFilterValue(newValue);
  };

  return (
    <Combobox
      name={`${name}-filter`}
      selectPlaceholder=""
      searchPlaceholder={`Search ${name}...`}
      clearable
      onValuesChange={handleValuesChange}
      options={options}
      values={value}
      className={cn(
        "flex-grow focus:!border active:!border data-[state=open]:!border",
        {
          "border-0": !value || value.length === 0,
        },
      )}
      multiple={multiple}
    />
  );
};

export const tableFilterComboboxFn = (
  row: Row<any>,
  id: string,
  filterValues: any,
) => {
  if (!filterValues || filterValues?.length === 0) return true;
  return filterValues.includes(row.getValue(id));
};

export const tableFilterComboboxArrayFn = (
  row: Row<any>,
  id: string,
  filterValues: any,
) => {
  if (!filterValues || filterValues?.length === 0) return true;
  return filterValues.some((value: string) =>
    (row.getValue(id) as string[]).includes(value),
  );
};
