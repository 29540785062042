import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { toJS } from "mobx";
import { observer } from "mobx-react-lite";

import { API_PATHS } from "~/api/ApiPaths";
import { Button } from "~/components/ui-rework/button";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "~/components/ui-rework/card";
import useAxios from "~/hooks/useAxios";
import useCheckPermission from "~/hooks/useCheckPermission";
import EmptyStateGraphicDashboard from "~/images/Empty-State-Graphic-Dashboard.svg";
import idesign from "~/images/iDesign_avatar.png";
import { useStore } from "~/models/Root";
import { PERMISSION } from "~/models/UserManager";

const AlignDashboard: React.FC = observer(() => {
  const { getAuthToken, getAppUser, getNoAvailableProgramsFlag } = useStore();
  const [appUser] = React.useState(getAppUser());
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const weekday = dayNames[new Date().getDay()];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[new Date().getMonth()];
  const date = new Date().getDate();
  const { data, requestUrl, authToken, error, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const fetchLogo = useRef(true);
  const [logoData, setLogoData] = useState<string>("");
  const [logoSuccessful, setLogoSuccessful] = useState(false);
  const {getRoleType} = useCheckPermission();
  const roleType = getRoleType();

  useEffect(() => {
    if (appUser.selected_partner_id !== 0) {
      fetchLogo.current = true;
    }
  }, [appUser.selected_partner_id]);

  useEffect(() => {
    if (data?.entity && !fetchLogo.current) {
      if (data.entity.includes("data:image/png;base64,")) {
        setLogoData(data.entity);
        setLogoSuccessful(true);
      } else {
        setLogoSuccessful(false);
      }
    } else if (fetchLogo.current && appUser.selected_partner_id !== 0) {
      fetchLogo.current = false;
      requestUrl.current = `${API_PATHS.GET_PARTNER_LOGO}/${appUser.selected_partner_id}`;
      authToken.current = getAuthToken();
      fetch();
    } else if (error) {
      console.log(error);
    }
  }, [
    authToken,
    data,
    error,
    fetch,
    getAuthToken,
    requestUrl,
    fetchLogo,
    appUser.selected_partner_id,
  ]);

  return (
    <div className="px-14 py-8 w-full h-full bg-background light flex justify-between">
      <title>Dashboard - Align App</title>
      <div className="w-3/5 h-full flex flex-col">
        <div className="mr-[5rem] flex items-center justify-between">
          <div className="flex">
            {logoSuccessful ? (
              <div className="rounded-lg mr-6 p-2 bg-white">
                <img src={logoData} className="h-[5rem]" alt="partner logo" />
              </div>
            ) : (
              <div className=" rounded-lg mr-6 p-2 bg-white">
                <img src={idesign} className="h-[5rem]" alt="" />
              </div>
            )}
            <div>
              <h6 className="text-base font-bold mb-4">
                {weekday}, {month} {date}
              </h6>
              <h6 className="text-base font-bold">Welcome {appUser.name}</h6>
            </div>
          </div>
        </div>
        {getNoAvailableProgramsFlag() ? (
          <div className="flex flex-row gap-9 items-center justify-center">
            <img
              className="pointer-events-none select-none w-[632px] h-[632px]"
              alt="Edit Mappings"
              src={EmptyStateGraphicDashboard}
            />
            <div className="flex flex-col gap-6 justify-center">
              <div>
                <h3 className="text-lg font-semibold text-foreground mb-[15px]">
                  {roleType === "Agency Admin"
                    ? "Whoops! There are no programs for your selected partner."
                    : roleType === "Agency Editor"
                    ? "Whoops! You don’t have access to any programs for your selected partner."
                    : roleType === "Partner User"
                    ? "You don’t have access to any programs yet!"
                    : null}
                </h3>
                <p className="text-base text-foregroundmax-w-64">
                  {roleType === "Agency Admin" ? (
                    <>
                      Go to the <b>Admin</b> tab to create a program to view
                      reports, including time on task, alignment, and details
                      about courses, modules, and activities.
                    </>
                  ) : roleType === "Agency Editor" ? (
                    "Reach out to your administrator to get access to curriculum, data visualizations, reporting, and more."
                  ) : roleType === "Partner User" ? (
                    "Reach out to your administrator to view reports, including time on task, alignment, and details about courses, modules, and activities."
                  ) : null}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="mb-6 mt-12 flex">
            <div className="p-8 pr-6 rounded-lg rounded-tl-none rounded-br-none w-fit bg-ocean-20">
              <h2 className="text-xl font-bold mb-4">Quick Links</h2>
              <div className="flex flex-row justify-between mb-2 items-center">
                <div className="mr-12 flex flex-row">
                  Want to view reports for a program?
                </div>
                <div>
                  <Link className="text-nowrap" to="/reports">
                    <Button className="text-nowrap" variant="ghost">
                      Go to Reports
                    </Button>
                  </Link>
                </div>
              </div>
              {appUser.basic_permissions.includes(
                PERMISSION.VIEW_PROGRAM_DETAILS,
              ) && (
                <div className="flex flex-row justify-between mb-2 items-center">
                  <div className="mr-12 flex flex-row">
                    Want to view programs?
                  </div>
                  <div>
                    <Link className="text-nowrap" to="/programs">
                      <Button className="text-nowrap" variant="ghost">
                        Go to Programs
                      </Button>
                    </Link>
                  </div>
                </div>
              )}
              {(appUser.basic_permissions.includes(
                PERMISSION.MANAGE_GLOBAL_LISTS,
              ) ||
                appUser.basic_permissions.includes(
                  PERMISSION.VIEW_GLOBAL_LISTS,
                )) && (
                <div className="flex flex-row justify-between mb-2 items-center">
                  <div className="mr-12 flex flex-row">Want to view lists?</div>
                  <div>
                    <Link className="text-nowrap" to="/lists">
                      <Button className="text-nowrap" variant="ghost">
                        Go to Lists
                      </Button>
                    </Link>
                  </div>
                </div>
              )}
              {appUser.basic_permissions.includes(PERMISSION.MANAGE_USERS) && (
                <div className="flex flex-row justify-between mb-2 items-center">
                  <div className="mr-12 flex flex-row">
                    Need to invite a user to Align?
                  </div>
                  <Link
                    className="text-nowrap"
                    to="/admin"
                    state={{ addUser: true }}
                  >
                    <Button className="text-nowrap" variant="ghost">
                      Add User
                    </Button>
                  </Link>
                </div>
              )}
              {appUser.basic_permissions.includes(
                PERMISSION.MANAGE_PARTNERS,
              ) && (
                <div className="flex flex-row justify-between mb-2 items-center">
                  <div className="mr-12 flex flex-row">
                    Need to add a partner to Align?
                  </div>
                  <Link
                    className="text-nowrap"
                    to="/admin"
                    state={{ addPartner: true }}
                  >
                    <Button className="text-nowrap" variant="ghost">
                      Add Partner
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="w-fit">
        <Card className="max-w-[425px]">
          <CardHeader>
            <CardTitle>Welcome to Align</CardTitle>
            <CardDescription className="font-bold w-full">
              Align makes reporting and accreditation a breeze.
            </CardDescription>
          </CardHeader>
          <CardDescription>
            <span>
              Our users have comprehensive, up-to-date curriculum maps and data
              and can generate complete documentation with the click of a
              button. More importantly, they go into the accreditation process
              knowing that their learner experiences meet standards. Align
              instantly produces the exact documentation your accreditor is
              looking for (and more).
              <br /> <br />
              No more sifting through endless spreadsheets... definitely no more
              reams of paper in binders.
            </span>
          </CardDescription>
        </Card>
      </div>
    </div>
  );
});

export default AlignDashboard;
