import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  InfoIcon,
  MailIcon,
  UserIcon,
} from "lucide-react";
import { z } from "zod";

import { isProduction } from "~/api/ApiPaths";
import { useCreateUserAndSendInvite } from "~/components/admin/hooks/useCreateUserAndSendInvite";
import { useGetPartnerListQuery } from "~/components/admin/hooks/useGetPartnerListQuery";
import { Button } from "~/components/ui-rework/button";
import { Combobox } from "~/components/ui-rework/combobox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui-rework/form";
import { Input } from "~/components/ui-rework/input";
import { Label } from "~/components/ui-rework/label";
import { RadioGroup, RadioGroupItem } from "~/components/ui-rework/radio-group";
import { SelectOption } from "~/components/ui-rework/select";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "~/components/ui-rework/sheet";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "~/components/ui-rework/toggle-group";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui-rework/tooltip";
import { cn } from "~/lib/utils";
import { useStore } from "~/models/Root";

const UserType = {
  Agency: "Agency User",
  Partner: "Partner User",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type UserType = (typeof UserType)[keyof typeof UserType];

const RoleType = { Administrator: "administrator", Editor: "editor" } as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type RoleType = (typeof RoleType)[keyof typeof RoleType];

type InviteUserSheetProps = {
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const InviteUserSheet = ({ open, setIsOpen }: InviteUserSheetProps) => {
  const { appUser } = useStore();

  const [step, setStep] = useState(1);

  const [userType, setUserType] = useState<UserType>(UserType.Agency);
  const [role, setRole] = useState<RoleType>(RoleType.Administrator);

  const form1 = useForm<z.infer<typeof form1Schema>>({
    mode: "onChange",
    resolver: zodResolver(form1Schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
  });
  const form2 = useForm<z.infer<typeof form2Schema>>({
    mode: "onChange",
    resolver: zodResolver(form1Schema),
    defaultValues: {
      partner_ids: [],
    },
  });

  const { data: partnerData, isSuccess } = useGetPartnerListQuery();

  const partnerOptions: SelectOption[] = isSuccess
    ? partnerData.entity?.map((partner: any) => ({
        value: partner.id.toString(),
        label: `${partner.name}`,
      }))
    : [];

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
    setStep(1);
    form1.reset({
      first_name: "",
      last_name: "",
      email: "",
    });
    form2.reset({
      partner_ids: [],
    });
    setUserType(UserType.Agency);
    setRole(RoleType.Administrator);
  };

  const onSuccess = () => {
    onOpenChange(false);
  };

  const { mutation } = useCreateUserAndSendInvite({ onSuccess });

  const isMultiplePartners = userType === UserType.Agency;

  const selectedPartners = form2
    .getValues()
    .partner_ids.map(
      (partner_id) =>
        partnerOptions.find((option) => option.value === partner_id.toString())
          ?.label,
    )
    .filter((label) => label !== undefined);
  const selectedPartner =
    selectedPartners.length > 0 ? selectedPartners[0] : undefined;

  useEffect(() => {
    form2.reset({ partner_ids: [] });
  }, [userType, role]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetTrigger asChild>
          <Button>Invite User</Button>
        </SheetTrigger>
        <SheetContent className="light bg-gradient-3 min-w-[500px]">
          <div className="mx-auto w-full flex flex-col h-full gap-6">
            <SheetHeader className="flex flex-col justify-items-center gap-6">
              <SheetTitle className="p-0 m-0 text-lg font-bold justify-items-center align-text-center">
                Invite User
              </SheetTitle>
              <div
                className="grid gap-2"
                style={{ gridTemplateColumns: "1fr 1fr" }}
              >
                <div className="h-1 bg-ocean-100 rounded-full" />
                <div
                  className={cn("h-1 bg-idesign-navy-20 rounded-full", {
                    "bg-ocean-100": step === 2,
                  })}
                />
              </div>
              <div className={cn({ hidden: step === 2 })}>
                <h3 className="font-bold mb-2 text-base">User Information</h3>
                <SheetDescription className="">
                  Provide the necessary details to invite a user to the
                  platform.
                </SheetDescription>
              </div>
            </SheetHeader>

            <Form {...form1}>
              <div
                className={cn("flex flex-col gap-6", { hidden: step === 2 })}
              >
                <FormField
                  control={form1.control}
                  name="first_name"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>First Name</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          icon={<UserIcon />}
                          className="bg-transparent"
                          placeholder="Enter Input"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form1.control}
                  name="last_name"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Last Name</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          icon={<UserIcon />}
                          className="bg-transparent"
                          placeholder="Enter Input"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form1.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          icon={<MailIcon />}
                          className="bg-transparent"
                          placeholder="Enter Input"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </Form>

            <Form {...form2}>
              <div
                className={cn("flex flex-col gap-6", { hidden: step === 1 })}
              >
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <h3 className="font-bold text-base">Type</h3>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger>
                        <InfoIcon />
                      </TooltipTrigger>
                      <TooltipContent
                        className="flex flex-col"
                        align="start"
                        side="bottom"
                      >
                        <p className="text-white text-nowrap">
                          <span className="font-bold">Agency users</span> can
                          access multiple partners.
                        </p>
                        <span className="text-white text-nowrap">
                          <span className="font-bold">Partner users</span> are
                          restricted to one specific partner.
                        </span>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                  <span>Select a type for this user.</span>
                  <ToggleGroup
                    type="single"
                    value={userType}
                    onValueChange={(value) => {
                      if (value === "Agency User") {
                        setUserType(value);
                        setRole("administrator");
                      } else if (value === "Partner User") {
                        setUserType(value);
                        setRole("editor");
                      }
                    }}
                    className="gap-4"
                  >
                    <ToggleGroupItem
                      value="Agency User"
                      className={cn(
                        "h-auto border px-6 py-4 border-idesign-navy-50",
                        "data-[state=on]:bg-ocean-20 data-[state=on]:border-idesign-navy-100",
                      )}
                    >
                      <div className="flex items-center gap-4">
                        <div className="flex items-center h-6 aspect-square justify-center rounded-full border border-idesign-navy-100">
                          {userType === "Agency User" && (
                            <div className="h-2.5 w-2.5 bg-ocean-110 rounded-full" />
                          )}
                        </div>
                        <div className="flex flex-col gap-4 items-start text-start">
                          <span className="font-bold">Agency</span>
                          <p className="text-sm">
                            Member of the agency institution
                          </p>
                        </div>
                      </div>
                    </ToggleGroupItem>
                    <ToggleGroupItem
                      value="Partner User"
                      className={cn(
                        "h-auto border px-6 py-4 border-idesign-navy-50",
                        "data-[state=on]:bg-ocean-20 data-[state=on]:border-idesign-navy-100",
                      )}
                    >
                      <div className="flex items-center gap-4">
                        <div className="flex items-center h-6 aspect-square justify-center rounded-full border border-idesign-navy-100">
                          {userType === "Partner User" && (
                            <div className="h-2.5 w-2.5 bg-ocean-110 rounded-full" />
                          )}
                        </div>
                        <div className="flex flex-col gap-4 items-start text-start">
                          <span className="font-bold">Partner</span>
                          <p className="text-sm">
                            Member of the partner institution
                          </p>
                        </div>
                      </div>
                    </ToggleGroupItem>
                  </ToggleGroup>
                </div>
                {userType === "Agency User" && (
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2">
                      <h3 className="font-bold text-base">Role</h3>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger>
                          <InfoIcon />
                        </TooltipTrigger>
                        <TooltipContent
                          className="flex flex-col gap-4 p-2"
                          align="start"
                          side="bottom"
                        >
                          <p className="text-white w-[138px]">
                            <span className="font-bold">Administrators</span>{" "}
                            have access to all programs.
                          </p>
                          <span className="text-white w-[138px]">
                            <span className="font-bold">Editors</span> can only
                            access programs to which administrators grant
                            permission.
                          </span>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    <span>Select a type for this user.</span>
                    <RadioGroup
                      onValueChange={(value) => {
                        if (value === "administrator" || value === "editor")
                          setRole(value);
                      }}
                      value={role}
                    >
                      <div className="flex items-center gap-2">
                        <RadioGroupItem
                          value="administrator"
                          id="radio-administrator"
                        />
                        <Label
                          htmlFor="radio-administrator"
                          className="mb-0 cursor-pointer"
                        >
                          Administrator
                        </Label>
                      </div>
                      <div className="flex items-center gap-2">
                        <RadioGroupItem value="editor" id="radio-editor" />
                        <Label
                          htmlFor="radio-editor"
                          className="mb-0 cursor-pointer"
                        >
                          Editor
                        </Label>
                      </div>
                    </RadioGroup>
                  </div>
                )}
                {userType === "Partner User" && (
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-4 items-center">
                      <h3 className="font-bold text-base">Role</h3>
                      <span className="text-base">Editor</span>
                    </div>
                  </div>
                )}

                <FormField
                  control={form2.control}
                  name={"partner_ids"}
                  render={({ field }) => (
                    <FormItem
                      className={cn("col-span-2", {
                        hidden: role !== "editor",
                      })}
                    >
                      <FormLabel className="font-bold">
                        {`Select Partner${
                          isMultiplePartners ? "(s)" : ""
                        } the user is associated with:`}
                      </FormLabel>
                      <FormControl>
                        <Combobox
                          clearable
                          searchPlaceholder={
                            isMultiplePartners
                              ? "Select Partner(s)"
                              : "Select Partner"
                          }
                          selectPlaceholder={
                            isMultiplePartners
                              ? "Select Partner(s)"
                              : "Select Partner"
                          }
                          multiple={
                            userType === "Agency User" && role === "editor"
                          }
                          options={partnerOptions}
                          values={
                            (field.value as unknown as string[]) ?? undefined
                          }
                          name={"partner_ids"}
                          onValuesChange={(values) => {
                            field.onChange(values);
                            if (form2.getValues("partner_ids").length === 0) {
                              form2.setError("partner_ids", {
                                message: "Select Partner(s)",
                              });
                            } else {
                              form2.clearErrors("partner_ids");
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </Form>

            {step === 2 && (
              <>
                {selectedPartners.length > 0 && (
                  <>
                    {userType === UserType.Partner ? (
                      <span className="">
                        <span className="font-bold">
                          {form1.getValues().first_name +
                            " " +
                            form1.getValues().last_name}
                        </span>{" "}
                        will be added to{" "}
                        <span className="font-bold">{selectedPartner}</span> as
                        an editor role.
                      </span>
                    ) : (
                      <span className="">
                        <span className="font-bold">
                          {form1.getValues().first_name +
                            " " +
                            form1.getValues().last_name}
                        </span>{" "}
                        will be added to{" "}
                        <span className="font-bold">{appUser.agency_name}</span>{" "}
                        as an editor role for the following partners:{" "}
                        {selectedPartners.map((partner, index) => {
                          return (
                            <span key={index}>
                              <span className="font-bold">{partner}</span>
                              {index === selectedPartners.length - 1
                                ? ""
                                : " & "}
                            </span>
                          );
                        })}
                        .
                      </span>
                    )}
                  </>
                )}
                {role === RoleType.Administrator && (
                  <span className="">
                    <span className="font-bold">
                      {form1.getValues().first_name +
                        " " +
                        form1.getValues().last_name}
                    </span>{" "}
                    will be added to{" "}
                    <span className="font-bold">{appUser.agency_name}</span> as
                    an administrator role.
                  </span>
                )}
              </>
            )}

            <SheetFooter className="mt-auto flex w-full">
              {step === 1 && (
                <Button
                  onClick={async () => {
                    await form1.trigger(["first_name", "last_name", "email"]);
                    if (
                      !form1.getFieldState("first_name").invalid &&
                      !form1.getFieldState("last_name").invalid &&
                      !form1.getFieldState("email").invalid
                    ) {
                      setStep(2);
                    }
                  }}
                  className="flex justify-self-end justify-between"
                >
                  Next
                  <ArrowRightIcon />
                </Button>
              )}
              {step === 2 && (
                <div className="flex w-full justify-between">
                  <Button
                    onClick={() => {
                      setStep(1);
                    }}
                    variant="outline"
                    className="flex justify-between justify-self-start"
                  >
                    <ArrowLeftIcon />
                    Back
                  </Button>
                  <Button
                    onClick={async () => {
                      if (mutation.isPending) return;

                      if (role === RoleType.Editor) {
                        await form2.trigger(["partner_ids"]);
                        if (form2.getValues("partner_ids").length === 0) {
                          form2.setError("partner_ids", {
                            message: isMultiplePartners
                              ? "Partner(s) required"
                              : "Partner required",
                          });
                        } else {
                          form2.clearErrors("partner_ids");
                        }
                        if (form2.getFieldState("partner_ids").invalid) return;
                      }

                      const roles =
                        role === RoleType.Editor
                          ? form2.getValues().partner_ids.map((partner_id) => {
                              return {
                                app_role_id: 2,
                                partner_id: parseInt(partner_id),
                                program_id: 0,
                              };
                            })
                          : [{ app_role_id: 1, partner_id: 0, program_id: 0 }];

                      const partner_id =
                        userType === UserType.Partner
                          ? parseInt(form2.getValues().partner_ids[0])
                          : 0;

                      mutation.mutate({
                        ...form1.getValues(),
                        ...form2.getValues(),
                        partner_id: partner_id,
                        agency_id: appUser.agency_id,
                        send_invite: true,
                        roles: roles,
                        app_environment: isProduction() ? "Production" : "Dev",
                      });
                    }}
                    className="flex justify-self-end justify-between"
                  >
                    Send Invite
                  </Button>
                </div>
              )}
            </SheetFooter>
          </div>
        </SheetContent>
        {/* <Dialog open={alertOpen}>
          <DialogContent className="dark" hideCloseButton>
            <DialogTitle className="flex flex-row items-center">
              <AlertOctagonIcon className="mr-2" />
              Discard this user invite?
            </DialogTitle>
            <DialogDescription>
              You will leave without inviting this user and lose all information
              entered.
            </DialogDescription>
            <DialogFooter>
              <DialogTrigger asChild>
                <Button variant="outline" onClick={() => setAlertOpen(false)}>
                  Cancel
                </Button>
              </DialogTrigger>
              <DialogTrigger asChild>
                <Button
                  variant="primary"
                  onClick={() => {
                    setAlertOpen(false);
                    form1.reset();
                    form2.reset();
                    setCloseConfirmed(true);
                    onOpenChange(false);
                  }}
                >
                  Discard
                </Button>
              </DialogTrigger>
            </DialogFooter>
          </DialogContent>
        </Dialog> */}
      </Sheet>
    </>
  );
};

const form1Schema = z.object({
  first_name: z.string().min(1, { message: "First Name is required" }),
  last_name: z.string().min(1, { message: "Last Name is required" }),
  email: z
    .string()
    .email("Invalid Email")
    .min(1, { message: "Email is required" }),
});

const form2Schema = z.object({
  partner_ids: z.array(z.string()).nonempty({ message: "Select Partner(s)" }),
});
