import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Building } from "lucide-react";

import { FormField } from "~/components/ui/form";
import { Button } from "~/components/ui-rework/button";
import {
  FormControl,
  FormItem,
  FormMessage,
} from "~/components/ui-rework/form";
import { Input } from "~/components/ui-rework/input";
import { Label } from "~/components/ui-rework/label";
import useOverrides from "~/hooks/useOverrides";

type EditProgramProps = {
  editProgramData: any;
};

const EditProgram = (props: EditProgramProps) => {
  const { editProgramData } = props;
  const { ProgramLabel } = useOverrides();
  const programLabel = ProgramLabel();
  const [disabled, setDisabled] = useState(false);
  const form = useFormContext();

  const code = useWatch({ control: form.control, name: "programCode" });
  const name = useWatch({ control: form.control, name: "programName" });

  useEffect(() => {
    if (code === editProgramData?.code && name === editProgramData?.name) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [code, name, editProgramData]);

  return (
    <div>
      <div className="flex flex-col gap-6">
        <h3 className="text-xl font-bold">Edit {programLabel}</h3>
        <FormField
          control={form.control}
          name="programCode"
          render={({ field }) => (
            <FormItem>
              <Label htmlFor="programCode" className="font-bold">
                Program Code (i.e. MHA)
              </Label>
              <FormControl>
                <Input
                  iconPosition="left"
                  icon={<Building />}
                  className="bg-transparent"
                  placeholder="Program Code"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="programName"
          render={({ field }) => (
            <FormItem>
              <Label htmlFor="programName" className="font-bold">
                Program Name (i.e. Masters of Health Administration)
              </Label>
              <FormControl>
                <Input
                  iconPosition="left"
                  icon={<Building />}
                  className="bg-transparent"
                  placeholder="Program Name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="w-full flex justify-end mt-6">
        <Button
          type="submit"
          disabled={
            !form.formState.isDirty || !form.formState.isValid || disabled
          }
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default EditProgram;
