import { useStore } from "~/models/Root";

interface Permission {
  agency_id: number;
  app_permission_id: number;
  app_role_id: number;
  app_users_id: number;
  created_at: number;
  created_by_id: number;
  id: number;
  partner_id: number;
  permission_token: string;
  program_id: number;
  scope: string;
}

const useCheckPermission = () => {
  const { getAppUser } = useStore();
  const appUser = getAppUser();

  const getRoleType = (): string => {
    if (appUser.user_type === "Agency User") {
      return appUser.user_permissions[0].app_role_id === 1
        ? "Agency Admin"
        : "Agency Editor";
    }
    return "Partner User";
  };

  //TODO
  //This function needs to be used instead of our current permission checking that uses the basic_permissions array
  //This function instead will check permissions based off of the selected partner/program
  const hasPermission = (permissionNumber: number): boolean => {
    return appUser.user_permissions.some((permission: Permission) => {
      return (
        permission.app_permission_id === permissionNumber &&
        permission.partner_id === appUser.selected_partner_id &&
        permission.program_id === appUser.selected_program_id
      );
    });
  };

  return { getRoleType, hasPermission };
};

export default useCheckPermission;
