import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";

import { CurriculumDialogBreadcrumb } from "~/components/program/dialogs/CurriculumDialogBreadcrumb";
import { Button } from "~/components/ui-rework/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui-rework/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui-rework/form";
import { Input } from "~/components/ui-rework/input";
import { Label } from "~/components/ui-rework/label";
import { Textarea } from "~/components/ui-rework/textarea";
import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import { cn } from "~/lib/utils";
import { IModule, ModuleEditable } from "~/models/AlignEditor";

const generateDisplayName = (
  data: ModuleEditable,
  module: IModule | undefined,
) => {
  return data.code && data.name
    ? `${data.code} - ${data.name}`
    : module?.display_name ?? "";
};

type ModuleDialogProps = {
  module?: IModule;
  children: ReactNode;
  onSubmit: (data: ModuleEditable) => void;
};

const ModuleDialog = ({ module, children, onSubmit }: ModuleDialogProps) => {
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      code: module?.code,
      name: module?.name,
      description: module?.description,
      week_number: module?.week_number ?? 0,
    },
  });

  const { isDirty, isValid, errors } = form.formState;

  const handleOpenChange = (open: boolean) => {
    if (open) {
      form.reset({
        code: module?.code,
        name: module?.name,
        description: module?.description,
        week_number: module?.week_number ?? 0,
      });
    }
  };

  const actionLabel = module ? "Edit" : "Add";
  const { moduleLabel } = useProgramEditorContext();
  const title = `${actionLabel} ${moduleLabel}`;

  return (
    <Dialog onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="light">
        <Form {...form}>
          <form
            autoComplete="off"
            onSubmit={form.handleSubmit((data) =>
              onSubmit({
                ...data,
                display_name: generateDisplayName(data, module),
              }),
            )}
          >
            <DialogTitle className="mt-1 mb-4">{title}</DialogTitle>

            <CurriculumDialogBreadcrumb courseId={module?.course_id} />

            <div className="grid gap-4 pb-4 pt-3">
              <div className="flex flex-col gap-2">
                <Label htmlFor="code" className="font-bold mb-0">
                  Code
                  <span className="pl-2 text-muted-foreground font-normal">
                    {"(required)"}
                  </span>
                </Label>
                <Input
                  id="code"
                  className="bg-transparent"
                  placeholder={`${moduleLabel} Code`}
                  {...form.register("code", {
                    required: "Code is required",
                    minLength: {
                      value: 1,
                      message: "Code is required",
                    },
                    setValueAs: (value) => value.trim(),
                  })}
                  aria-describedby={
                    errors.code?.message ? "code-error" : undefined
                  }
                />
                {errors.code?.message && (
                  <span
                    id="code-error"
                    className="text-sm font-medium text-error-50"
                  >
                    {errors.code.message}
                  </span>
                )}
              </div>

              <div className="flex flex-col gap-2">
                <Label htmlFor="name" className="font-bold mb-0">
                  Name
                  <span className="pl-2 text-muted-foreground font-normal">
                    {"(required)"}
                  </span>
                </Label>
                <Input
                  id="name"
                  className="bg-transparent"
                  placeholder={`${moduleLabel} Name`}
                  {...form.register("name", {
                    required: "Name is required",
                    minLength: {
                      value: 1,
                      message: "Name is required",
                    },
                    setValueAs: (value) => value.trim(),
                  })}
                  aria-describedby={
                    errors.name?.message ? "name-error" : undefined
                  }
                />
                {errors.name?.message && (
                  <span
                    id="name-error"
                    className="text-sm font-medium text-error-50"
                  >
                    {errors.name.message}
                  </span>
                )}
              </div>

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex justify-between w-full">
                      <FormLabel type="bold" htmlFor="description">
                        Description
                      </FormLabel>
                      <span className="text-muted-foreground text-sm self-center">
                        {field.value?.length ?? 0}/{2500}
                      </span>
                    </div>
                    <FormControl>
                      <Textarea
                        id="description"
                        {...field}
                        className={cn({
                          "border-error-foreground":
                            form.formState.errors.description,
                        })}
                        maxLength={2500}
                        onChange={field.onChange}
                        placeholder={`${moduleLabel} Description`}
                        value={field.value ?? undefined}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex flex-col gap-2">
                <Label htmlFor="week_number" className="font-bold mb-0">
                  Week
                </Label>
                <Input
                  id="week_number"
                  className="bg-transparent"
                  type="number"
                  min="0"
                  {...form.register("week_number", {
                    required: "Week is required",
                    min: 0,
                    valueAsNumber: true,
                  })}
                  aria-describedby={
                    errors.week_number?.message
                      ? "week_number-error"
                      : undefined
                  }
                />
                {errors.week_number?.message && (
                  <span
                    id="week_number-error"
                    className="text-sm font-medium text-error-50"
                  >
                    {errors.week_number.message}
                  </span>
                )}
              </div>
            </div>
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  type="submit"
                  disabled={!isDirty || !isValid}
                  className="font-normal"
                >
                  {module ? "Save Changes" : title}
                </Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ModuleDialog;
