import React from "react";

import { SortDirection } from "@tanstack/react-table";

type ChevronsSortIconProps = {
  direction: false | SortDirection;
};

export const ChevronsSortIcon = ({ direction }: ChevronsSortIconProps) => {
  return direction ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={direction === "asc" ? "transform rotate-180" : ""}
    >
      <path
        d="M7.5 15L12.5 20L17.5 15"
        stroke="#33C9FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9L12.5 4L17.5 9"
        stroke="#1F7999"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M7.5 15L12.5 20L17.5 15"
        stroke="#1F7999"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9L12.5 4L17.5 9"
        stroke="#1F7999"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
