import React, { useEffect, useRef, useMemo, useState } from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FilePenLineIcon, SearchIcon } from "lucide-react";

import { API_PATHS } from "~/api/ApiPaths";
import { ChevronsSortIcon } from "~/components/icons/ChevronsSortIcon";
import { ScrollArea } from "~/components/ui/scroll-area";
import { Button } from "~/components/ui-rework/button";
import { Input } from "~/components/ui-rework/input";
import {
  Pagination,
  PaginationContent,
  PaginationFirst,
  PaginationItem,
  PaginationLast,
  PaginationNext,
  PaginationPrevious,
} from "~/components/ui-rework/pagination";
import { Select } from "~/components/ui-rework/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui-rework/table";
import useAxios from "~/hooks/useAxios";
import { cn } from "~/lib/utils";
import { useStore } from "~/models/Root";
import { IProgramData } from "~/models/UserManager";

interface IUserPartnerDetails {
  programDetailsData: any;
  setEditProgramData: any;
}

const ProgramList: React.FC<IUserPartnerDetails> = (props) => {
  const { programDetailsData, setEditProgramData } = props;
  const displayReady = useRef(false);
  const [programData, setProgramData] = useState<IProgramData[]>([]);
  const { data, authToken, error, requestUrl, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const { getAuthToken } = useStore();
  const programsFetched = useRef(false);
  const programsSet = useRef(false);
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "name", desc: false },
  ]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  
  

  useEffect(() => {
    if (data?.issuccess && !programsSet.current && programsFetched.current) {
      const allProgramsArray: IProgramData[] = [];
      data.entity.forEach((item: IProgramData) => {
        allProgramsArray.push({
          id: item.id,
          partner_id: item.partner_id,
          display_name: item.display_name,
          code: item.code,
          name: item.name,
          total_credits: item.total_credits,
          total_tot_minutes: item.total_tot_minutes,
        });
      });
      setProgramData(allProgramsArray);
      programsSet.current = true;
      displayReady.current = false;
    } else if (!programsFetched.current) {
      programsFetched.current = true;
      displayReady.current = false;
      requestUrl.current = `${
        API_PATHS.GET_PROGRAMS
      }/${+programDetailsData.id}`;
      authToken.current = getAuthToken();
      fetch();
    } else if (error) {
      console.log(error);
    }
  }, [
    data,
    error,
    authToken,
    requestUrl,
    fetch,
    getAuthToken,
    programDetailsData,
    programsFetched,
    programsSet,
    displayReady,
  ]);

  const columns = useMemo<ColumnDef<IProgramData>[]>(
    () => [
      {
        id: "edit",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <Button
              className="p-0 hover:outline-0"
              variant="ghost"
              onClick={() => {
                console.log(row.original);
                setEditProgramData(row.original);
              }}
            >
              <FilePenLineIcon className="stroke-ocean-120" size="16px" />
            </Button>
          </div>
        ),
      },
      {
        accessorKey: "code",
        header: ({ column }) => {
          return (
            <div className="flex gap-2">
              <Button
                className={cn(
                  "p-0 items-center justify-start",
                  "hover:bg-transparent hover:!border-none hover:!outline-none active:bg-transparent",
                )}
                variant={"ghost"}
                onClick={() =>
                  column.toggleSorting(column.getIsSorted() === "asc")
                }
              >
                Code
                <ChevronsSortIcon direction={column.getIsSorted()} />
              </Button>
              <Input
                placeholder=""
                icon={<SearchIcon />}
                iconPosition="right"
                value={(column.getFilterValue() as string) ?? ""}
                onChange={(event) => column.setFilterValue(event.target.value)}
                className={cn(
                  "!bg-transparent text-base",
                  "flex-grow focus:!border active:!border",
                  {
                    "border-0":
                      column.getFilterValue() === undefined ||
                      column.getFilterValue() === "",
                  },
                )}
              />
            </div>
          );
        },
      },
      {
        accessorKey: "name",
        header: ({ column }) => {
          return (
            <div className="flex gap-2">
              <Button
                className={cn(
                  "p-0 items-center justify-start",
                  "hover:bg-transparent hover:!border-none hover:!outline-none active:bg-transparent",
                )}
                variant="ghost"
                onClick={() =>
                  column.toggleSorting(column.getIsSorted() === "asc")
                }
              >
                Name
                <ChevronsSortIcon direction={column.getIsSorted()} />
              </Button>
              <Input
                placeholder=""
                icon={<SearchIcon />}
                iconPosition="right"
                value={(column.getFilterValue() as string) ?? ""}
                onChange={(event) => column.setFilterValue(event.target.value)}
                className={cn(
                  "bg-transparent text-base",
                  "flex-grow focus:!border active:!border",
                  {
                    "border-0":
                      column.getFilterValue() === undefined ||
                      column.getFilterValue() === "",
                  },
                )}
              />
            </div>
          );
        },
      },
    ],
    [ setEditProgramData ],
  );

  const table = useReactTable({
    data: programData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  });

  const [pageIndex, setPageIndex] = useState(0);
  const firstPage = () => {
    table.firstPage();
    setPageIndex(0);
  };
  const previousPage = () => {
    table.previousPage();
    setPageIndex(pageIndex - 1);
  };
  const nextPage = () => {
    table.nextPage();
    setPageIndex(pageIndex + 1);
  };
  const lastPage = () => {
    table.lastPage();
    setPageIndex(table.getPageOptions().length - 1);
  };

  return (
    <div
      className="light h-full w-full grid gap-2 overflow-hidden rounded-md border-idesign-navy-120"
      style={{ gridTemplateRows: "auto auto 1fr" }}
    >
      <ScrollArea
        className="w-full"
        horizontal
        vertical
        thumbClassName="z-50 bg-gradient-1"
      >
        <Table className="border border-idesign-navy-120">
          <TableHeader className="bg-gradient-1 dark h-16">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="hover:bg-transparent">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className={cn(
                        "text-white border-x border-idesign-navy-120 p-2 text-base",
                        "last:border-r-0 first:border-x-0",
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  className={cn(
                    "hover:bg-gray-200 border-b border-idesign-navy-120 last:border-b-0 bg-white",
                    "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                  )}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className="p-2 py-4 border-x border-idesign-navy-120"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ScrollArea>
      <div className="flex flex-row items-center justify-center w-full py-1.5">
        <div className="min-w-fit font-bold">
          Showing Items {pageIndex * 10}-
          {pageIndex === table.getPageCount() - 1
            ? table.getRowCount()
            : (pageIndex + 1) * 10}{" "}
          of {table.getRowCount()}
        </div>
        {table.getPageCount() > 1 ? (
          <Pagination className="w-fit mx-0 pr-1 md:absolute right-0">
            <PaginationContent>
              <PaginationItem>
                <PaginationFirst
                  onClick={firstPage}
                  disabled={!table.getCanPreviousPage()}
                />
              </PaginationItem>
              <PaginationItem>
                <PaginationPrevious
                  onClick={previousPage}
                  disabled={!table.getCanPreviousPage()}
                />
              </PaginationItem>
              Page
              <Select
                fieldName="pageSelect"
                options={table.getPageOptions().map((page) => ({
                  label: (page + 1).toString(),
                  value: page.toString(),
                }))}
                handleChange={(_, newValue) => {
                  setPageIndex(parseInt(newValue));
                  table.setPageIndex(parseInt(newValue));
                }}
                value={pageIndex.toString()}
                className="w-min"
              />
              <PaginationItem>
                <PaginationNext
                  disabled={!table.getCanNextPage()}
                  onClick={nextPage}
                />
              </PaginationItem>
              <PaginationItem>
                <PaginationLast
                  disabled={!table.getCanNextPage()}
                  onClick={lastPage}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        ) : null}
      </div>
    </div>
  );
};

export default ProgramList;
