import React, { Fragment, useMemo, useState } from "react";

import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronDown, ChevronUp, FilePenLineIcon } from "lucide-react";
import { observer } from "mobx-react-lite";

import ProgramDialog from "~/components/program/dialogs/ProgramDialog";
import CourseTable from "~/components/program/tables/CourseTable";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "~/components/ui/table";
import { useToast } from "~/components/ui-rework/use-toast";
import { cn } from "~/lib/utils";
import {
  useStore,
  IProgramTreeNode,
  ProgramEditable,
} from "~/models/AlignEditor";

const ProgramTable = observer(() => {
  const dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  const columns = useMemo<ColumnDef<IProgramTreeNode>[]>(
    () => [
      {
        id: "expand",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <button
              {...{
                onClick: () => row.toggleExpanded(!row.getIsExpanded()),
                style: { cursor: "pointer" },
                "data-testid": "program-expand", // First program found will be tested upon regardless.
              }}
            >
              {row.getIsExpanded() ? (
                <ChevronUp size="16px" />
              ) : (
                <ChevronDown size="16px" />
              )}
            </button>
          </div>
        ),
      },
      {
        id: "edit",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <ProgramDialog
              program={row.original}
              onSubmit={(data) => handleEditProgram(data, row.original)}
            >
              <button title="Edit Program" aria-label="Edit Program">
                <FilePenLineIcon className="stroke-ocean-120" size="16px" />
              </button>
            </ProgramDialog>
          </div>
        ),
      },
      {
        id: "code",
        header: "Code",
        cell: ({ row }) => (
          <div className="break-words">{row.original.code}</div>
        ),
      },
      {
        id: "name",
        header: "Name",
        cell: ({ row }) => (
          <div className="break-words">{row.original.name}</div>
        ),
      },
      {
        id: "created_at",
        header: "Created At",
        cell: ({ row }) => {
          const dateToFormat = new Date(row.original.created_at);
          return (
            <div>
              {dateToFormat.toLocaleDateString("en-US", dateOptions)}{" "}
              {dateToFormat.toLocaleTimeString("en-US")}
            </div>
          );
        },
      },
      {
        id: "updated_at",
        header: "Updated At",
        cell: ({ row }) => {
          const dateToFormat = new Date(row.original.updated_at);
          return (
            <div>
              {dateToFormat.toLocaleDateString("en-US", dateOptions)}{" "}
              {dateToFormat.toLocaleTimeString("en-US")}
            </div>
          );
        },
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { getCurrentProgram, saveProgram } = useStore();

  const { toast } = useToast();

  const [data, setData] = useState<IProgramTreeNode[]>(getCurrentProgram());

  const handleEditProgram = (
    programUpdates: ProgramEditable,
    programOriginal: IProgramTreeNode,
  ) => {
    const program = { ...programOriginal, ...programUpdates };
    const newProgramData = saveProgram(program);
    setData(newProgramData);
    toast({
      variant: "success",
      title: "Changes Saved in Queue",
      description: `Program changes saved in queue.`,
    });
  };

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table className="table-fixed border border-idesign-navy-120 rounded-b-md border-collapse">
      <colgroup>
        <col style={{ width: "33px" }} />
        <col style={{ width: "33px" }} />
        <col style={{ width: "100px" }} />
        <col />
        <col />
        <col />
      </colgroup>
      <TableHeader className="bg-gradient-1">
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id} className={cn("hover:bg-transparent")}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead
                  className={cn(
                    "text-white font-bold border-x border-idesign-navy-120 p-2 py-4",
                    "last:border-r-0 first:border-x-0 [&:nth-child(2)]:border-x-0",
                  )}
                  key={header.id}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <TableRow
                key={row.id + "_original"}
                className={cn(
                  "hover:bg-gray-200 border-b-0",
                  "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                  {
                    "bg-muted": row.index % 2 !== 0,
                    "bg-white": row.index % 2 === 0,
                  },
                )}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className="p-2 border-x border border-b-0 border-idesign-navy-120"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
              {row.getIsExpanded() ? (
                <tr
                  key={row.id + "_expanded"}
                  className={cn({
                    "bg-muted": row.index % 2 !== 0,
                    "bg-white": row.index % 2 === 0,
                  })}
                >
                  <TableCell
                    colSpan={columns.length}
                    className="pl-8 pr-0 py-0"
                  >
                    <CourseTable
                      className={cn({
                        "border-b-0":
                          row.index === table.getRowModel().rows.length - 1,
                      })}
                    />
                  </TableCell>
                </tr>
              ) : null}
            </Fragment>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
});

export default ProgramTable;
