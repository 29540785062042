import { useContext, useEffect } from "react";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { ApiUrls } from "~/api/ApiPaths";
import { AxiosContext } from "~/api/AxiosInstance";
import { useStore as useRootStore } from "~/models/Root";

export const useGetPartnerListQuery = () => {
  const axiosInstance = useContext(AxiosContext) ?? axios;
  const { getAuthToken, appUser } = useRootStore();

  const queryFn = (): Promise<any> =>
    axiosInstance
      .request({
        method: "GET",
        url: ApiUrls.partners.getPartnerList(appUser.agency_id),
        headers: { Authorization: getAuthToken() },
      })
      .then((response) => response.data);

  const query = useQuery({
    queryKey: [
      "getPartnerListQuery",
      `/partners/getPartnerList/${appUser.agency_id}`,
    ],
    queryFn,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["getPartnerListQuery"] });
  }, [queryClient]);

  return query;
};
