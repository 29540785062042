import React, { useMemo } from "react";

import { observer } from "mobx-react-lite";

import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import { useStore } from "~/models/AlignEditor";

type CurriculumDialogBreadcrumbProps = {
  courseId?: number;
  moduleId?: number;
  activityId?: number;
};

export const CurriculumDialogBreadcrumb = observer(
  ({ courseId, moduleId, activityId }: CurriculumDialogBreadcrumbProps) => {
    const { programLabel, courseLabel, moduleLabel, activityLabel } =
      useProgramEditorContext();
    const { getProgram, getCourse, getModule, getActivity } = useStore();

    const courseDisplayName = useMemo(
      () => (courseId ? getCourse(courseId)?.display_name : undefined),
      [courseId, getCourse],
    );
    const moduleDisplayName = useMemo(
      () =>
        courseId && moduleId
          ? getModule(courseId, moduleId)?.display_name
          : undefined,
      [courseId, moduleId, getModule],
    );
    const activityDisplayName = useMemo(
      () =>
        courseId && moduleId && activityId
          ? getActivity(courseId, moduleId, activityId)?.display_name
          : undefined,
      [courseId, moduleId, activityId, getActivity],
    );

    return (
      <div className="grid gap-x-6" style={{ gridTemplateColumns: "auto 1fr" }}>
        <span className="font-bold">{programLabel}</span>
        <span>{getProgram()?.display_name}</span>

        {courseDisplayName ? (
          <>
            <span className="font-bold">{courseLabel}</span>
            <span>{courseDisplayName}</span>
          </>
        ) : null}

        {moduleDisplayName ? (
          <>
            <span className="font-bold">{moduleLabel}</span>
            <span>{moduleDisplayName}</span>
          </>
        ) : null}

        {activityDisplayName ? (
          <>
            <span className="font-bold">{activityLabel}</span>
            <span>{activityDisplayName}</span>
          </>
        ) : null}
      </div>
    );
  },
);
