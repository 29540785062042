import * as React from "react";
import { Control } from "react-hook-form";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui-rework/form";
import { cn } from "~/lib/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <div className="flex relative">
        <textarea
          className={cn(
            "flex min-h-[80px] w-full rounded-[10px] border border-input bg-transparent p-2.5 text-base",
            "ring-offset-select-ring",
            "placeholder:text-muted-foreground",
            "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-4",
            "disabled:cursor-not-allowed disabled:outline-none disabled:border-disable disabled:placeholder:text-disable-foreground disabled:bg-disable disabled:text-disable-foreground",
            "textarea-resizer",
            className,
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);
Textarea.displayName = "Textarea";

type FormTextAreaProps = React.ComponentPropsWithRef<typeof Textarea> & {
  fieldName: string;
  formControl: Control;
  description?: string;
  error?: string;
  label?: string;
  maxLength?: number;
  required?: boolean;
};

const FormTextArea = React.forwardRef<HTMLTextAreaElement, FormTextAreaProps>(
  (
    {
      fieldName,
      formControl,
      description,
      error,
      label,
      maxLength,
      required,
      ...props
    },
    ref,
  ) => {
    const hasDescription = description && description.length > 0;
    const hasError = error && error.length > 0;
    const hasLabel = label && label.length > 0;

    const [count, setCount] = React.useState(
      props.value?.toString().length || 0,
    );

    return (
      <FormField
        control={formControl}
        name="textarea"
        render={({ field }) => (
          <FormItem>
            <div className="flex justify-between w-full">
              <div>
                {hasLabel && (
                  <FormLabel htmlFor="textarea">
                    {required && <span className="text-required">*</span>}
                    <span className="font-bold">{label}</span>
                    {required && (
                      <span className="pl-2 text-muted-foreground">
                        {"(required)"}
                      </span>
                    )}
                  </FormLabel>
                )}
              </div>

              {maxLength && maxLength > 0 ? (
                <span className="text-muted-foreground text-sm self-center">
                  {count}/{maxLength}
                </span>
              ) : null}
            </div>
            <FormControl>
              <Textarea
                {...field}
                {...props}
                className={cn(props.className, {
                  "border-error-foreground": hasError,
                })}
                maxLength={maxLength}
                ref={ref}
                onChange={(e) => {
                  setCount(e.target.value.length);
                  field.onChange?.(e);
                }}
              />
            </FormControl>
            {hasError && <FormMessage />}
            {hasDescription && <FormDescription>{description}</FormDescription>}
          </FormItem>
        )}
      />
    );
  },
);

export { Textarea, FormTextArea };
