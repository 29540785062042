import { useContext } from "react";

import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ApiUrls } from "~/api/ApiPaths";
import { AxiosContext } from "~/api/AxiosInstance";
import { useToast } from "~/components/ui-rework/use-toast";
import { useStore as useRootStore } from "~/models/Root";

type Role = {
  partner_id?: number;
  program_id?: number;
  app_role_id: number;
};

type CreateUserAndSendInvitePayload = {
  agency_id: number;
  email: string;
  first_name: string;
  last_name: string;
  partner_id?: number;
  roles: Role[];
  send_invite: boolean;
  title?: string;
  app_environment: "Dev" | "Production";
};

type CreateUserAndSendInviteProps = {
  onSuccess: () => void;
};

export const useCreateUserAndSendInvite = ({
  onSuccess,
}: CreateUserAndSendInviteProps) => {
  const axiosInstance = useContext(AxiosContext) ?? axios;
  const { getAuthToken } = useRootStore();

  const { toast } = useToast();

  const handleMutationSuccess = (data: any) => {
    onSuccess();
    toast({
      variant: "success",
      title: "Success!",
      description: "User invite has been sent saved successfully.",
      duration: 3000,
    });
  };

  const handleMutationError = (error: any) => {
    toast({
      variant: "destructive",
      title: "Uh oh! Something went wrong",
      description:
        error?.response?.data?.message ??
        error.message ??
        "There was a problem with your request.",
      duration: 3000,
    });
  };

  const mutation = useMutation({
    mutationFn: (payload: CreateUserAndSendInvitePayload) => {
      return axiosInstance.request({
        method: "POST",
        url: ApiUrls.users.createUserAndSendInvite,
        headers: { Authorization: getAuthToken() },
        data: payload,
      });
    },
    onSuccess: handleMutationSuccess,
    onError: handleMutationError,
  });

  return { mutation };
};
