import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { GridCellProps } from "@progress/kendo-react-grid";
import { ArrowLeft } from "lucide-react";
import { z } from "zod";

import { ApiUrls } from "~/api/ApiPaths";
import AddNewProgram from "~/components/admin/AddNewProgram";
import EditProgram from "~/components/admin/EditProgram";
import ProgramList from "~/components/admin/ProgramList";
import { DialogContext } from "~/components/AlignDialog";
import { Button } from "~/components/ui-rework/button";
import { Form } from "~/components/ui-rework/form";
import { SheetHeader, SheetTitle } from "~/components/ui-rework/sheet";
import { toast } from "~/components/ui-rework/use-toast";
import useAxios from "~/hooks/useAxios";
import useOverrides from "~/hooks/useOverrides";
import { useStore } from "~/models/Root";
import { IProgramItem } from "~/models/UserManager";

interface IProgramDetailsDrawer {
  programDetailsData: GridCellProps | any;
}

const ProgramDetailsDrawer = (props: IProgramDetailsDrawer) => {
  const { programDetailsData } = props;
  const { data, authToken, error, requestUrl, payload, fetch, resetAll } =
    useAxios({
      method: "POST",
      initialValue: null,
    });
  const { getAuthToken } = useStore();
  const { ProgramLabel, ProgramsLabel } = useOverrides();
  const programLabel = ProgramLabel();
  const programsLabel = ProgramsLabel();
  const [isDetailsView, setIsDetailsView] = useState(true);
  const { openDialog } = useContext(DialogContext);
  const [finished, setFinished] = useState(false);
  const [editProgramData, setEditProgramData] = useState<IProgramItem | null>(
    null,
  );

  const formSchema = z.object({
    programCode: z
      .string()
      .min(1, { message: `${programLabel} Code is required.` }),
    programName: z
      .string()
      .min(1, { message: `${programLabel} Name is required.` }),
  });

  const form1 = useForm({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: {
      programCode: "",
      programName: "",
    },
  });
  const form2 = useForm({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: {
      programCode: "",
      programName: "",
    },
  });

  const onSubmitForm1 = (data: any) => {
    const programObject: IProgramItem = {
    id: 0, //needed for interface object however will be assigned an id by the backend
      partner_id: programDetailsData?.id,
      display_name: data.programCode + " - " + data.programName,
      code: data.programCode,
      name: data.programName,
      available_list_ids: [],
    };
    createProgram(programObject);
  };

  const onSubmitForm2 = (data: any) => {
    const programObject: IProgramItem = {
      id: editProgramData?.id ?? 0,
      partner_id: editProgramData?.partner_id ?? "",
      display_name: data.programCode + " - " + data.programName,
      code: data.programCode,
      name: data.programName,
      available_list_ids: [],
    };
    updateProgram(programObject);
  }

  const createProgram = (programObject: IProgramItem) => {
    setFinished(false);
    resetAll();
    requestUrl.current = ApiUrls.programs.createProgram;
    authToken.current = getAuthToken();
    payload.current = {
      partner_id: programObject.partner_id,
      display_name: programObject.display_name,
      code: programObject.code,
      name: programObject.name,
      available_list_ids: programObject.available_list_ids,
    };
    fetch();
  };

  const updateProgram = (programObject: IProgramItem) => {
    setFinished(false);
    resetAll();
    requestUrl.current = ApiUrls.programs.updateProgram;
    authToken.current = getAuthToken();
    payload.current = {
      program_id: programObject.id,
      display_name: programObject.display_name,
      code: programObject.code,
      name: programObject.name,
      available_list_ids: programObject.available_list_ids,
    };
    fetch();
  };

  useEffect(() => {
    if (data?.issuccess && !finished) {
      setIsDetailsView(true);
      setFinished(true);
      setEditProgramData(null);
      form1.reset();
      form2.reset();
      const partnerName = programDetailsData?.short_name;
      toast({
        variant: "success",
        title: "Success",
        description:
          "New " +
          programLabel +
          " has been successfully added to " +
          partnerName,
      });
    } else if (error) {
      console.log(error);
    }
  }, [
    data,
    error,
    openDialog,
    programDetailsData,
    setFinished,
    finished,
    programLabel,
    form1,
    form2,
  ]);

  useEffect(() => {
    if (editProgramData) {
      form2.reset({
        programCode: editProgramData.code,
        programName: editProgramData.name,
      });
    }
  } , [editProgramData, form2]);

  return (
    <div className="h-full">
      {isDetailsView && !editProgramData && (
        <SheetHeader className="mb-6">
          <SheetTitle>{programsLabel}</SheetTitle>
        </SheetHeader>
      )}
      <div className="flex flex-row justify-between items-center mb-6">
        <div className="flex flex-row justify-between items-center">
          {isDetailsView && !editProgramData ? (
            <>
              <h3 className="text-lg font-bold">
                {programDetailsData?.short_name}'s {programLabel} Details
              </h3>
            </>
          ) : (
            <div className="flex">
              <Button
                variant="outline"
                onClick={() => {
                  setIsDetailsView(true);
                  setEditProgramData(null);
                }}
              >
                <ArrowLeft size={24} className="mr-2" />
                Back to {programsLabel}
              </Button>
            </div>
          )}
        </div>

        {isDetailsView && !editProgramData && (
          <Button
            onClick={() => {
              setIsDetailsView(false);
            }}
            testid="admin-add-program"
          >
            Add New {programLabel}
          </Button>
        )}
      </div>

      {editProgramData ? (
        <Form {...form2}>
          <form
            className="h-full"
            onSubmit={form2.handleSubmit((data) => onSubmitForm2(data))}
          >
            <EditProgram editProgramData={editProgramData} />
          </form>
        </Form>
      ) : isDetailsView ? (
        <ProgramList
          programDetailsData={programDetailsData}
          setEditProgramData={setEditProgramData}
        />
      ) : (
        <Form {...form1}>
          <form
            className="h-full"
            onSubmit={form1.handleSubmit((data) => onSubmitForm1(data))}
          >
            <AddNewProgram programDetailsData={programDetailsData} />
          </form>
        </Form>
      )}
    </div>
    
  );
};

export default ProgramDetailsDrawer;
