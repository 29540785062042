import * as React from "react";

import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/lib/utils";

export const sizes = {
  xs: "text-xs",
  s: "text-sm",
  m: "text-base",
  l: "text-l",
  xl: "text-xl",
};

const types = {
  regular: "font-normal",
  bold: "font-bold",
};

const labelVariants = cva(
  "leading-none peer-disabled:cursor-not-allowed text-foreground mb-0",
  {
    variants: {
      size: sizes,
      type: types,
    },
    defaultVariants: {
      size: "m",
      type: "regular",
    },
  },
);

export const LABEL_SIZE_KEYS = Object.keys(sizes) as Array<keyof typeof sizes>;

export const LABEL_TYPE_KEYS = Object.keys(types) as Array<keyof typeof types>;

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants>
>(({ className, size, type, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants({ size, type }), className)}
    {...props}
  />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label, labelVariants };
