import React, { CSSProperties } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Row, flexRender } from "@tanstack/react-table";

import { TableCell, TableRow } from "~/components/ui/table";
import { cn } from "~/lib/utils";
import { GripVertical } from "lucide-react";
import { Button } from "~/components/ui-rework/button";

type RowData = {
  id: any;
};

type DraggableRowProps<T extends RowData> = {
  row: Row<T>;
  className?: string;
};

const DraggableRow = <T extends RowData>({
  row,
  className,
}: DraggableRowProps<T>) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
  };

  return (
    // connect row ref to dnd-kit, apply important styles
    <TableRow
      ref={setNodeRef}
      style={style}
      className={cn("bg-white hover:!bg-ocean-10", className)}
    >
      <DraggableRowCells row={row as unknown as Row<RowData>} />
    </TableRow>
  );
};

/**
 * @dnd-kit has some performance issues on large lists in its current build and we
 * are unsure when the maintainer will address them. This component is memoized to
 * prevent unnecessary re-renders of the row cells while dragging.
 */
const DraggableRowCells = React.memo(
  <T extends RowData>({ row }: { row: Row<T> }) => {
    return (
      <>
        {row.getVisibleCells().map((cell) => (
          <TableCell
            key={cell.id}
            className={cn(
              "py-2 border border-idesign-navy-120 border-b-0",
              "last:border-r-0 first:border-l-0",
              {
                "p-0  ": cell.column.columnDef.id === "drag-handle",
              },
            )}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </>
    );
  },
);

const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    <div className="h-full w-full flex items-center justify-center">
      <Button
        {...attributes}
        {...listeners}
        size="icon-only-sm"
        variant="ghost"
        className={cn(
          "px-0 py-3",
          "hover:bg-transparent hover:border-0 hover:outline-0 hover:cursor-grab",
          "active:bg-transparent active:border-0 active:outline-0 active:cursor-grabbing",
        )}
      >
        <GripVertical size="24" stroke="black" />
      </Button>
    </div>
  );
};

export { DraggableRow, RowDragHandleCell };
